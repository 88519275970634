<template>
	<div class="Home">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12 mb-6>
				<h2 class="indigo--text text--darken-4">Bem-vindo, {{session.nome}}!</h2>
				<p>Utilize o menu principal para acessar as áreas que deseja.</p>
			</v-flex>

			<v-flex xs12 v-if="[1, 2].indexOf(parseInt(session.id_cargo_usuario)) >= 0 && data.apresentarAlertaEstoque">
				<h2 class="indigo--text text--darken-4 mb-2">Alertas de estoque</h2>
				
				<v-alert text prominent type="error" icon="mdi-alert-rhombus-outline" v-for="item in data.listagemEstoque" v-bind:key="item.id_subproduto">
					O subproduto <strong>{{item.desc_subproduto}}</strong> tem somente <strong>{{item.saldo}} unidades</strong>, e precisa de reposição!
				</v-alert>
			</v-flex>

			<v-flex xs12>
				<h2 class="indigo--text text--darken-4 mb-2">Aniversariantes do mês</h2>
				<v-data-table
					:headers="listagemHeaders"
					:items="data.listagemGeral"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.hoje="{ item }">
						<v-chip class="ma-2 white--text text--white" color="success" v-if="item.hoje == '1'">É hoje!!! <v-icon>mdi-party-popper</v-icon></v-chip>
					</template>
				</v-data-table>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	data() {
		return {
			session : {
				nome: localStorage.getItem('nome_usuario'),
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario'))
			},
			data: {
				listagemGeral: [{nome: 'Buscando...'}],
				listagemEstoque: [],
				apresentarAlertaEstoque: false,
			}
		}
	},
	methods: {
		buscaAniversariantesDoMes() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'colaboradores/listagem_aniversatiantes_do_mes',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemGeral = r.body.listagemGeral;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaAlertasEstoque() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'estoque/listagem_alertas',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemEstoque = r.body.listagemEstoque;
	                	thisEl.data.apresentarAlertaEstoque = r.body.apresentarAlertaEstoque;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		}
	},
	computed: {
		listagemHeaders() {
			return [
				{ text: 'Nome', value: 'nome', class: 'blue darken-4 white--text' },
				{ text: '', value: 'hoje', class: 'blue darken-4 white--text' },
				{ text: 'Data Nascimento', value: 'data_nascimento_formated', class: 'blue darken-4 white--text' },
				{ text: 'Função', value: 'desc_cargo_colaborador', class: 'blue darken-4 white--text' }
			];
		}
	},
	mounted() {
		this.buscaAniversariantesDoMes();
		this.buscaAlertasEstoque();
	}
};
</script>
