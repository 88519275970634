<template>
	<div class="administrativo_produtos">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Subprodutos</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
				<v-dialog scrollable v-model="showDialogCadastro" max-width="700">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
							<v-icon left>add</v-icon>
							<span>Novo Subproduto</span>
						</v-btn>
					</template>
					<v-card height="fit-content">
						<v-system-bar color="blue darken-4"></v-system-bar>

						<v-card-title class="headline grey--text text--darken-2">Cadastrar novo subproduto</v-card-title>

						<v-card-text>
							<v-form class="my-3" ref="formCadastramento">
								<v-row>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-card-text-outline" label="Descrição" v-model="cadastro.desc_subproduto" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-box-shadow" label="Quantidade por produto" v-model="cadastro.quantidade_por_produto" :type="'number'" min="1"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-package-variant" label="Produto" multiple v-model="cadastro.id_produto" :items="data.listagemProdutos" item-text="desc_produto" item-value="id_produto" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-alert-circle-outline" label="Quantidade mínima para alerta de estoque" v-model="cadastro.estoque_minimo_alerta" :type="'number'" min="1"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-select prepend-icon="mdi-qrcode-edit" label="Vincular serial no estoque?" v-model="cadastro.vincular_serial_estoque" :items="['Não', 'Sim']"></v-select>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions class="my-5">
							<v-btn depressed dark color="blue darken-4" @click="showDialogCadastro = !showDialogCadastro">
								<v-icon left>close</v-icon>
								<span>Cancelar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="submitCadastro" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar</span>
							</v-btn>
						</v-card-actions>

						<v-system-bar color="blue-grey darken-1"></v-system-bar>
					</v-card>
				</v-dialog>
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pr-3">
						<h5>Filtros Gerais</h5>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Descrição do subproduto" v-model="filters.desc_subproduto" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Produto" v-model="filters.desc_produto" :items="data.listagemProdutos" item-text="desc_produto" item-value="desc_produto" outlined dense clearable></v-autocomplete>
					</v-flex>
					
					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-data-table
					:headers="listagemHeaders"
					:items="data.listagemGeral"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.actions="{ item }">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
									<v-icon>menu</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item>
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="editarSubproduto(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar Subproduto
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item>
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="excluirSubproduto(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-tag-remove</v-icon> Excluir Subproduto
										</v-list-item-title>
									</router-link>
								</v-list-item>
							</v-list>
						</v-menu>
					</template>
					<template v-slot:item.dt_criacao="{ item }">
						{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
					</template>
					<template v-slot:item.dt_alteracao="{ item }">
						{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
					</template>
					<template v-slot:item.desc_informar_placa_na_venda="{ item }">
						<v-chip class="ma-2 white--text text--white" :color="`${(item.informar_placa_na_venda == 1) ? 'light-blue' : 'default'}`">{{item.desc_informar_placa_na_venda}}</v-chip>
					</template>
				</v-data-table>
				<v-flex xs12 class="text-end">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
								<v-icon>file_download</v-icon>
								<span>Exportar planilha</span>
							</v-btn>
						</template>
						<v-list>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
									</v-list-item-title>
								</router-link>
							</v-list-item>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
									</v-list-item-title>
								</router-link>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogEdit" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar Produto</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formEdicao">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Descrição" v-model="dadosEdicao.desc_subproduto" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-alert-circle-outline" label="Quantidade por produto" v-model="dadosEdicao.quantidade_por_produto" :type="'number'" min="1"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-package-variant" label="Produto" v-model="dadosEdicao.id_produto_array" multiple :items="data.listagemProdutos" item-text="desc_produto" item-value="id_produto" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-alert-circle-outline" label="Quantidade mínima para alerta de estoque" v-model="dadosEdicao.estoque_minimo_alerta" :type="'number'" min="1"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-select prepend-icon="mdi-qrcode-edit" label="Vincular serial no estoque?" v-model="dadosEdicao.vincular_serial_estoque" :items="['Não', 'Sim']"></v-select>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEdit = !showDialogEdit">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogExcluir" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Excluir Subproduto</v-card-title>

				<v-card-text>
					<v-alert prominent text type="warning" border="left" icon="mdi-alert-outline">
						O subproduto será excluído. Esta ação não poderá ser desfeita.
					</v-alert>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogExcluir = !showDialogExcluir">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitExclusao()" :loading="loadingStatus">
						<v-icon left>mdi-tag-remove</v-icon>
						<span>Excluir</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario'))
			},
			cadastro: {
				desc_subproduto: '',
				id_produto: [],
				estoque_minimo_alerta: 0,
				quantidade_por_produto: 1,
				vincular_serial_estoque: 'Não'
			},
			rules: {
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				],
				selectValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null || 'Campo obrigatório'
				]
			},
			data: {
				listagemGeral: [{desc_produto: 'Buscando...'}],
				listagemProdutos: [{desc_produto: 'Buscando...'}]
			},
			filters: {
				desc_produto: '',
				desc_subproduto: ''
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,
			
			dadosEdicao: {},
			showDialogEdit: false,

			dadosExclusao: {},
			showDialogExcluir: false
		}
	},
	methods: {
		exportarListagemFiltros() {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			
			thisEl.$http.post(//requisição ajax
                'subproduto/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'subprodutos', []);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral() {
			let dados = {};

			dados.headers = Object.keys(this.data.listagemGeral[0]).map((item) => {
				return {name: item};
			});

			dados.values = this.data.listagemGeral;
			
			console.log(dados);

			this.exportXlsAjax(dados, 'subprodutos', []);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		submitCadastro () {
			if (this.$refs.formCadastramento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.cadastro;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'subproduto/criar_subproduto',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogCadastro = false;

	                        		thisEl.cadastro = {
										desc_subproduto: '',
										id_produto: [],
										estoque_minimo_alerta: 0,
										quantidade_por_produto: 1,
										vincular_serial_estoque: 'Não'
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		excluirSubproduto(dados) {
			this.dadosExclusao = {...dados};
			this.showDialogExcluir = true;
		},
		submitExclusao() {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosExclusao;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
                'subproduto/excluir_subproduto',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogExcluir = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		editarSubproduto (dados) {
			this.dadosEdicao = {...dados};
			
			this.dadosEdicao.id_produto_array = [];
			if (this.dadosEdicao.id_produto != '') {
				this.dadosEdicao.id_produto_array = this.dadosEdicao.id_produto.split(',').map(e => e.trim());
			}

			this.showDialogEdit = true;
		},
		submitEdicao () {
			if (this.$refs.formEdicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.dadosEdicao;
				dataToSend.id_produto = dataToSend.id_produto_array;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'subproduto/atualizar_subproduto',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogEdit = false;
	                        	}, 1000);

                            	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'subproduto/listagem_subprodutos',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemGeral = r.body.listagemGeral;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'subproduto/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemProdutos = r.body.listagemProdutos;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
	},
	computed: {
		cargosParaCriacao() {
			var ids = [];
			this.data.listagemCargos.forEach(function(item, key){
				if (item.id_cargo != 1) {
					ids.push(item);
				}
			});
			return ids;
		},
		listagemHeaders() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Subproduto',
					value: 'id_subproduto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Descrição',
					value: 'desc_subproduto',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_subproduto) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_subproduto.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Quantidade por produto',
					value: 'quantidade_por_produto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'ID Produto vinculado',
					value: 'id_produto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Produto vinculado',
					value: 'desc_produto',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_produto) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_produto.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Alerta estoque mínimo',
					value: 'estoque_minimo_alerta',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Vincular serial no estoque',
					value: 'vincular_serial_estoque',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
