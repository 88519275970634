<template>
	<div class="administrativo_unidades_operacionais">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Unidades Operacionais</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
				<v-dialog scrollable v-model="showDialogCadastro" max-width="700">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
							<v-icon left>add</v-icon>
							<span>Nova Unidade</span>
						</v-btn>
					</template>
					<v-card height="fit-content">
						<v-system-bar color="blue darken-4"></v-system-bar>

						<v-card-title class="headline grey--text text--darken-2">Cadastrar nova unidade</v-card-title>

						<v-card-text>
							<v-form class="my-3" ref="formCadastramento">
								<v-row>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-clipboard-text" label="CNPJ" v-model="cadastro.cnpj" :rules="rules.inputCnpj" counter maxlength="14"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-office-building" label="Nome Fantasia" v-model="cadastro.nome_fantasia" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field prepend-icon="mdi-card-text-outline" label="Razão Social" v-model="cadastro.razao_social" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="cadastro.endereco" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-file-input accept=".jpg, .jpeg, .png" label="Anexar arquivo da logo (.jpg, .jpeg, .png)" v-model="fileNameholderLogo" show-size @change="fileSelectedLogo" prepend-icon="insert_drive_file"></v-file-input>
									</v-col>

									<v-col cols="12">
										<v-alert outlined type="info" prominent border="left">
											<strong>Dados bancários</strong> e <strong>Dados de contato</strong> são necessários para as faturas emitidas pela Unidade Operacional.
										</v-alert>
									</v-col>

									<v-col cols="12">
										<h2 class="grey--text text--darken-2">Dados bancários</h2>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-bank" label="Instituição bancária" v-model="cadastro.banco" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-qrcode" label="Chave PIX" v-model="cadastro.chave_pix" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-office-building" label="Agência" v-model="cadastro.agencia" :rules="rules.inputTextValidate" counter maxlength="10"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-card-bulleted-outline" label="Conta corrente" v-model="cadastro.conta_corrente" :rules="rules.inputTextValidate" counter maxlength="20"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-file-input prepend-icon="mdi-qrcode-plus" accept=".jpg, .jpeg, .png" label="Anexar imagem do QR Code (.jpg, .jpeg, .png)" v-model="fileNameholderQrCode" show-size @change="fileSelectedQrCode"></v-file-input>
									</v-col>

									<v-col cols="12">
										<h2 class="grey--text text--darken-2">Dados de contato</h2>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-email-outline" label="E-mail" v-model="cadastro.email" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-whatsapp" label="Telefone (ex.: 11953534747)" v-model="cadastro.telefone" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions class="my-5">
							<v-btn depressed dark color="blue darken-4" @click="showDialogCadastro = !showDialogCadastro">
								<v-icon left>close</v-icon>
								<span>Cancelar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="submitCadastro" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar</span>
							</v-btn>
						</v-card-actions>

						<v-system-bar color="blue-grey darken-1"></v-system-bar>
					</v-card>
				</v-dialog>
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pr-3">
						<h5>Filtros Gerais</h5>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="CNPJ" v-model="filters.cnpj" outlined dense counter clearable maxlength="14"></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Razão Social" v-model="filters.razao_social" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Nome Fantasia" v-model="filters.nome_fantasia" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Endereço" v-model="filters.endereco" outlined dense clearable></v-text-field>
					</v-flex>
					
					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-data-table
					:headers="listagemHeaders"
					:items="data.listagemGeral"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.actions="{ item }">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
									<v-icon>menu</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item>
									<router-link to="">
										<v-list-item-title class="grey--text text--darken-2" @click="editarUnidade(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar Unidade
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.arquivo_logo != null">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="abrirArquivoLogo(item.arquivo_logo)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-download</v-icon> Ver Logo
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.arquivo_qrcode != null">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="abrirArquivoQrCode(item.arquivo_qrcode)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-download</v-icon> Ver Qr Code
										</v-list-item-title>
									</router-link>
								</v-list-item>
							</v-list>
						</v-menu>
					</template>
					<template v-slot:item.cnpj="{ item }">
						{{ mascara_cnpj(item.cnpj) }}
					</template>
					<template v-slot:item.telefone="{ item }">
						{{ mascara_telefone(item.telefone) }}
					</template>
					<template v-slot:item.dt_criacao="{ item }">
						{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
					</template>
					<template v-slot:item.dt_alteracao="{ item }">
						{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
					</template>
				</v-data-table>
				<v-flex xs12 class="text-end">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
								<v-icon>file_download</v-icon>
								<span>Exportar planilha</span>
							</v-btn>
						</template>
						<v-list>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
									</v-list-item-title>
								</router-link>
							</v-list-item>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
									</v-list-item-title>
								</router-link>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogStoreEdit" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar Unidade</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formEdicao">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-clipboard-text" label="CNPJ" :type="'number'" v-model="dadosEdicao.cnpj" :rules="rules.inputCnpj" counter maxlength="14"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-office-building" label="Nome Fantasia" v-model="dadosEdicao.nome_fantasia" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Razão Social" v-model="dadosEdicao.razao_social" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="dadosEdicao.endereco" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-file-input accept=".jpg, .jpeg, .png" label="Atualizar arquivo da logo (.jpg, .jpeg, .png)" v-model="fileNameholderLogo" show-size @change="fileSelectedLogoEdicao" prepend-icon="insert_drive_file"></v-file-input>
							</v-col>

							<v-col cols="12">
								<v-alert outlined type="info" prominent border="left">
									<strong>Dados bancários</strong> e <strong>Dados de contato</strong> são necessários para as faturas emitidas pela Unidade Operacional.
								</v-alert>
							</v-col>

							<v-col cols="12">
								<h2 class="grey--text text--darken-2">Dados bancários</h2>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-bank" label="Instituição bancária" v-model="dadosEdicao.banco" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-qrcode" label="Chave PIX" v-model="dadosEdicao.chave_pix" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-office-building" label="Agência" v-model="dadosEdicao.agencia" :rules="rules.inputTextValidate" counter maxlength="10"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-bulleted-outline" label="Conta corrente" v-model="dadosEdicao.conta_corrente" :rules="rules.inputTextValidate" counter maxlength="20"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-file-input prepend-icon="mdi-qrcode-plus" accept=".jpg, .jpeg, .png" label="Anexar imagem do QR Code (.jpg, .jpeg, .png)" v-model="fileNameholderQrCode" show-size @change="fileSelectedQrCodeEdicao"></v-file-input>
							</v-col>

							<v-col cols="12">
								<h2 class="grey--text text--darken-2">Dados de contato</h2>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-email-outline" label="E-mail" v-model="dadosEdicao.email" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-whatsapp" label="Telefone (ex.: 11953534747)" :type="'number'" v-model="dadosEdicao.telefone" :rules="rules.inputTelefone"  counter maxlength="11"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogStoreEdit = !showDialogStoreEdit">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo: Number(localStorage.getItem('id_cargo'))
			},
			cadastro: {
				cnpj: '',
				razao_social: '',
				nome_fantasia: '',
				endereco: '',
				arquivo_logo: null,
				arquivo_qrcode: null,
				banco: '',
				agencia: '',
				conta_corrente: '',
				chave_pix: '',
				email: '',
				telefone: ''
			},
			rules: {
				inputCnpj: [
					v => v != '' || 'Campo obrigatório',
					v => v.length == 14 || 'Exatamente 14 caracteres, preenchidos com zeros a esquerda'
				],
				inputTelefone: [
					v => v != '' || 'Campo obrigatório',
					v => v.length >= 10 || 'Telefones com DDD, entre 10 (fixo) e 11 (celular) caracteres',
					v => v.length <= 11 || 'Telefones com DDD, entre 10 (fixo) e 11 (celular) caracteres'
				],
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				],
				selectValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null || 'Campo obrigatório'
				]
			},
			data: {
				listagemGeral: [{cnpj: 'Buscando...'}]
			},
			filters: {
				cnpj: '',
				razao_social: '',
				nome_fantasia: '',
				endereco: ''
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,
			
			dadosEdicao: {},
			showDialogStoreEdit: false,

			fileNameholderLogo: null,
			fileNameholderQrCode: null
		}
	},
	methods: {
		abrirArquivoLogo(arquivo) {
			window.open(this.$http.options.root + 'resources/unidades_operacionais_logos/' + arquivo, '_blank');
		},
		abrirArquivoQrCode(arquivo) {
			window.open(this.$http.options.root + 'resources/unidades_operacionais_qrcodes/' + arquivo, '_blank');
		},
		fileSelectedLogo($event){
			this.cadastro.arquivo_logo = $event;
        },
        fileSelectedLogoEdicao($event){
			this.dadosEdicao.arquivo_logo = $event;
        },
        fileSelectedQrCode($event){
			this.cadastro.arquivo_qrcode = $event;
        },
        fileSelectedQrCodeEdicao($event){
			this.dadosEdicao.arquivo_qrcode = $event;
        },
		exportarListagemFiltros() {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			
			thisEl.$http.post(//requisição ajax
                'unidade_operacional/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'unidades_operacionais', []);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral() {
			let dados = {};

			dados.headers = Object.keys(this.data.listagemGeral[0]).map((item) => {
				return {name: item};
			});

			dados.values = this.data.listagemGeral;
			
			console.log(dados);

			this.exportXlsAjax(dados, 'unidades_operacionais', []);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		submitCadastro () {
			if (this.$refs.formCadastramento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				let formData = new FormData();

				formData.append('token', localStorage.getItem('token'));
				formData.append('cnpj', this.cadastro.cnpj);
				formData.append('razao_social', this.cadastro.razao_social);
				formData.append('nome_fantasia', this.cadastro.nome_fantasia);
				formData.append('endereco', this.cadastro.endereco);
				formData.append('arquivo_logo', this.cadastro.arquivo_logo);
				formData.append('arquivo_qrcode', this.cadastro.arquivo_qrcode);
				formData.append('banco', this.cadastro.banco);
				formData.append('agencia', this.cadastro.agencia);
				formData.append('conta_corrente', this.cadastro.conta_corrente);
				formData.append('chave_pix', this.cadastro.chave_pix);
				formData.append('email', this.cadastro.email);
				formData.append('telefone', this.cadastro.telefone);

				thisEl.$http.post(//requisição ajax
                    'unidade_operacional/criar_unidade',
                    formData,
                    {
                    	headers: {'Content-Type': 'multipart/form-data'}
                    }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogCadastro = false;

	                        		thisEl.cadastro = {
										cnpj: '',
										razao_social: '',
										nome_fantasia: '',
										endereco: '',
										arquivo_logo: null,
										banco: '',
										agencia: '',
										conta_corrente: '',
										chave_pix: '',
										email: '',
										telefone: ''
									};

									thisEl.fileNameholderLogo = null;
									thisEl.fileNameholderQrCode = null;
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		editarUnidade (dados) {
			this.dadosEdicao = {...dados};
			this.showDialogStoreEdit = true;
		},
		submitEdicao () {
			if (this.$refs.formEdicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				let formData = new FormData();

				formData.append('token', localStorage.getItem('token'));
				formData.append('id_unidade', this.dadosEdicao.id_unidade);
				formData.append('cnpj', this.dadosEdicao.cnpj);
				formData.append('razao_social', this.dadosEdicao.razao_social);
				formData.append('nome_fantasia', this.dadosEdicao.nome_fantasia);
				formData.append('endereco', this.dadosEdicao.endereco);
				formData.append('arquivo_logo', this.dadosEdicao.arquivo_logo);
				formData.append('arquivo_qrcode', this.dadosEdicao.arquivo_qrcode);
				formData.append('banco', this.dadosEdicao.banco);
				formData.append('agencia', this.dadosEdicao.agencia);
				formData.append('conta_corrente', this.dadosEdicao.conta_corrente);
				formData.append('chave_pix', this.dadosEdicao.chave_pix);
				formData.append('telefone', this.dadosEdicao.telefone);
				formData.append('email', this.dadosEdicao.email);

				thisEl.$http.post(//requisição ajax
                    'unidade_operacional/atualizar_unidade',
                    formData,
                    {
                    	headers: {'Content-Type': 'multipart/form-data'}
                    }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogStoreEdit = false;
	                        		
	                        		thisEl.fileNameholderLogo = null;
									thisEl.fileNameholderQrCode = null;
	                        	}, 1000);

                            	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'unidade_operacional/listagem_unidades',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemGeral = r.body.listagemGeral;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		mascara_cnpj(cnpj) {
			if (cnpj != undefined) {
				return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
			}
		},
		mascara_telefone(telefone) {
			if (telefone != undefined) {
				if (telefone.length == 11) {//celular
					return telefone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, "($1) $2$3-$4");
				} else {//fixo
					return telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
				}
			}
		}
	},
	computed: {
		listagemHeaders() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Unidade',
					value: 'id_unidade',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CNPJ',
					value: 'cnpj',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cnpj) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cnpj.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Razão Social',
					value: 'razao_social',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.razao_social) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.razao_social.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Nome Fantasia',
					value: 'nome_fantasia',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome_fantasia) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome_fantasia.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Endereço',
					value: 'endereco',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.endereco) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.endereco.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'E-mail',
					value: 'email',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Telefone',
					value: 'telefone',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Banco',
					value: 'banco',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Agência',
					value: 'agencia',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Conta corrente',
					value: 'conta_corrente',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Chave PIX',
					value: 'chave_pix',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
	}
};
</script>
