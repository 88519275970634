<template>
	<div class="administrativo_veiculos">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Gestão de Veículos</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
				<v-dialog scrollable v-model="showDialogCadastro" max-width="700">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
							<v-icon left>add</v-icon>
							<span>Novo Veículo</span>
						</v-btn>
					</template>
					<v-card height="fit-content">
						<v-system-bar color="blue darken-4"></v-system-bar>

						<v-card-title class="headline grey--text text--darken-2">Cadastrar novo veículo</v-card-title>

						<v-card-text>
							<v-form class="my-3" ref="formCadastramento">
								<v-row>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-card-text-outline" label="Descrição" v-model="cadastro.desc_veiculo" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-counter" label="Placa" v-model="cadastro.placa" :rules="rules.inputTextValidate" counter maxlength="7"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-autocomplete prepend-icon="mdi-account-arrow-right-outline" label="Colaborador responsável" v-model="cadastro.id_colaborador" :items="data.listagemColaboradores" item-text="nome" item-value="id_colaborador" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-counter" label="Km inicial" :type="'number'" v-model="cadastro.km_inicial" counter></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-car-multiple" label="Propriedade" v-model="cadastro.id_tipo_propriedade" :items="data.listagemTiposPropriedade" item-text="tipo_propriedade" item-value="id_tipo_propriedade" clearable></v-autocomplete>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions class="my-5">
							<v-btn depressed dark color="blue darken-4" @click="showDialogCadastro = !showDialogCadastro">
								<v-icon left>close</v-icon>
								<span>Cancelar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="submitCadastro" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar</span>
							</v-btn>
						</v-card-actions>

						<v-system-bar color="blue-grey darken-1"></v-system-bar>
					</v-card>
				</v-dialog>
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pr-3">
						<h5>Filtros Gerais</h5>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Descrição do veículo" v-model="filters.desc_veiculo" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Colaborador" v-model="filters.nome" outlined dense clearable :items="data.listagemColaboradores" item-text="nome" item-value="nome"></v-autocomplete>
					</v-flex>
					
					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-data-table
					:headers="listagemHeaders"
					:items="data.listagemGeral"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.actions="{ item }">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
									<v-icon>menu</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item>
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="editarVeiculo(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar Veículo
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item>
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="excluirVeiculo(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-delete-outline</v-icon> Excluir Veículo
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item>
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="historicoColaboradores(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-account-arrow-right-outline</v-icon> Histórico de Colaboradores
										</v-list-item-title>
									</router-link>
								</v-list-item>
							</v-list>
						</v-menu>
					</template>
					<template v-slot:item.dt_criacao="{ item }">
						{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
					</template>
					<template v-slot:item.dt_alteracao="{ item }">
						{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
					</template>
				</v-data-table>
				<v-flex xs12 class="text-end">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
								<v-icon>file_download</v-icon>
								<span>Exportar planilha</span>
							</v-btn>
						</template>
						<v-list>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
									</v-list-item-title>
								</router-link>
							</v-list-item>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
									</v-list-item-title>
								</router-link>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogEdit" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar Veículo</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formEdicao">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Descrição" v-model="dadosEdicao.desc_veiculo" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-counter" label="Número de série" v-model="dadosEdicao.placa" counter maxlength="50"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-autocomplete prepend-icon="mdi-account-arrow-right-outline" label="Colaborador responsável" v-model="dadosEdicao.id_colaborador" :items="data.listagemColaboradores" item-text="nome" item-value="id_colaborador" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-counter" label="Km inicial" :type="'number'" v-model="dadosEdicao.km_inicial" counter></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-car-multiple" label="Propriedade" v-model="dadosEdicao.id_tipo_propriedade" :items="data.listagemTiposPropriedade" item-text="tipo_propriedade" item-value="id_tipo_propriedade" clearable></v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEdit = !showDialogEdit">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogExcluir" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Excluir Veículo</v-card-title>

				<v-card-text>
					<v-alert prominent text type="warning" border="left" icon="mdi-alert-outline">
						O veículo será excluído. Esta ação não poderá ser desfeita.
					</v-alert>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogExcluir = !showDialogExcluir">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitExclusao()" :loading="loadingStatus">
						<v-icon left>mdi-delete-outline</v-icon>
						<span>Excluir</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogHistorico" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Histórico do Veículo {{dadosHistorico.desc_veiculo}}</v-card-title>

				<v-card-text>
					<v-data-table
						:headers="listagemHeadersHistorico"
						:items="data.listagemHistoricoVeiculo"
						:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
						class="grey lighten-4 grey--text text--darken-2"
						style="white-space:nowrap;"
					>
					</v-data-table>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="indigo darken-4" class="my-5" @click="exportarListagemHistorico(dadosHistorico.desc_veiculo)" :loading="loadingStatusExport">
						<v-icon>file_download</v-icon>
						<span>Exportar histórico</span>
					</v-btn>
					<v-btn depressed dark color="blue darken-4" @click="showDialogHistorico = !showDialogHistorico">
						<v-icon left>close</v-icon>
						<span>Fechar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario'))
			},
			cadastro: {
				desc_veiculo: '',
				placa: '',
				id_colaborador: 0,
				km_inicial: 0,
				id_tipo_propriedade: null
			},
			rules: {
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				]
			},
			data: {
				listagemGeral: [{cnpj: 'Buscando...'}],
				listagemColaboradores: [{nome: 'Buscando...'}],
				listagemHistoricoVeiculo: [{nome: 'Carregando...'}],
				listagemTiposPropriedade: [{tipo_propriedade: 'Buscando...'}],
			},
			filters: {
				desc_veiculo: '',
				nome: ''
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,
			
			dadosEdicao: {},
			showDialogEdit: false,

			dadosExclusao: {},
			showDialogExcluir: false,

			dadosHistorico: {},
			showDialogHistorico: false,
		}
	},
	methods: {
		exportarListagemFiltros() {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			
			thisEl.$http.post(//requisição ajax
                'veiculo/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'veiculos', []);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemHistorico(veiculo) {
			let dados = {};

			dados.headers = Object.keys(this.data.listagemHistoricoVeiculo[0]).map((item) => {
				return {name: item};
			});

			dados.values = this.data.listagemHistoricoVeiculo;
			
			this.exportXlsAjax(dados, 'historico_veiculo_' + veiculo, []);
		},
		exportarListagemGeral() {
			let dados = {};

			dados.headers = Object.keys(this.data.listagemGeral[0]).map((item) => {
				return {name: item};
			});

			dados.values = this.data.listagemGeral;
			
			this.exportXlsAjax(dados, 'veiculos', []);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		submitCadastro () {
			if (this.$refs.formCadastramento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.cadastro;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'veiculo/criar_veiculo',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogCadastro = false;

	                        		thisEl.cadastro = {
										desc_veiculo: '',
										placa: '',
										id_colaborador: 0,
										km_inicial: '',
										id_tipo_propriedade: 0
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		historicoColaboradores(dados) {
			this.dadosHistorico = {...dados};
			this.showDialogHistorico = true;

			var thisEl = this;
			thisEl.data.listagemHistoricoVeiculo = [{nome: 'Carregando...'}];

			thisEl.$http.post(//requisição ajax
	            'veiculo/listagem_historico_veiculo',
	            {
	            	token: localStorage.getItem('token'),
	            	id_veiculo: dados.id_veiculo
	            },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemHistoricoVeiculo = r.body.listagemHistorico;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		excluirVeiculo(dados) {
			this.dadosExclusao = {...dados};
			this.showDialogExcluir = true;
		},
		submitExclusao() {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosExclusao;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
                'veiculo/excluir_veiculo',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogExcluir = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		editarVeiculo (dados) {
			this.dadosEdicao = {...dados};
			this.showDialogEdit = true;
		},
		submitEdicao () {
			if (this.$refs.formEdicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.dadosEdicao;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'veiculo/atualizar_veiculo',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogEdit = false;
	                        	}, 1000);

                            	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'veiculo/listagem_veiculos',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemGeral = r.body.listagemGeral;
	                	thisEl.data.listagemColaboradores = r.body.listagemColaboradores;
	                	thisEl.data.listagemTiposPropriedade = r.body.listagemTiposPropriedade;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		}
	},
	computed: {
		listagemHeadersHistorico() {
			return [
				{
					text: 'Colaborador Responsável',
					value: 'nome',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Vínculo',
					value: 'dt_vinculo',
					class: 'blue darken-4 white--text'
				}
			];
		},
		listagemHeaders() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Veiculo',
					value: 'id_veiculo',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Descrição',
					value: 'desc_veiculo',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_veiculo) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_veiculo.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Placa',
					value: 'placa',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Colaborador Responsável',
					value: 'nome_colaborador',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Km inicial',
					value: 'km_inicial',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Propriedade',
					value: 'tipo_propriedade',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
	}
};
</script>
