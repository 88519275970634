<template>
	<div class="administrativo_clientes">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Gestão de Estoque</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pr-3">
						<h5>Filtros Gerais</h5>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Produto" v-model="filters.desc_produto" :items="data.listagemProdutos" item-text="desc_produto" item-value="desc_produto" outlined dense clearable></v-autocomplete>
					</v-flex>
					
					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-card>
					<v-tabs v-model="activeTab" background-color="grey lighten-4">
						<v-tabs-slider color="indigo darken-4"></v-tabs-slider>
						<v-tab v-for="item in tablist" :key="item" class="indigo--text text--darken-4">{{ item }}</v-tab>
					</v-tabs>

					<v-tabs-items v-model="activeTab">
						<v-tab-item key="Consolidado">
							<v-card flat>
								<v-card-text>
									<v-data-table
										:headers="listagemHeadersConsolidado"
										:items="data.listagemConsolidado"
										:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
										class="grey lighten-4 grey--text text--darken-2"
										style="white-space:nowrap;"
									>
										<template v-slot:item.actions="{ item }">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
														<v-icon>menu</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item>
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="lancarReposicao(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-package-up</v-icon> Lançar reposição
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item>
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="lancarPerdas(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-archive-arrow-down-outline</v-icon> Lançar perda de produção
															</v-list-item-title>
														</router-link>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
										<template v-slot:item.saldo="{ item }">
											<v-chip class="ma-2 white--text text--white" :color="((Number(item.saldo) <= Number(item.estoque_minimo_alerta)) ? 'red' : 'blue')">{{item.saldo}} <v-icon v-if="Number(item.saldo) <= Number(item.estoque_minimo_alerta)">mdi-alert-circle-outline</v-icon></v-chip>
										</template>
									</v-data-table>
									<v-flex xs12 class="text-end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
													<v-icon>file_download</v-icon>
													<span>Exportar planilha</span>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemConsolidado, 'consolidado')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
														</v-list-item-title>
													</router-link>
												</v-list-item>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros('consolidado')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
														</v-list-item-title>
													</router-link>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-flex>
								</v-card-text>
							</v-card>
						</v-tab-item>
						<v-tab-item key="Detalhado">
							<v-card flat>
								<v-card-text>
									<v-data-table
										:headers="listagemHeadersDetalhado"
										:items="data.listagemDetalhado"
										:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
										class="grey lighten-4 grey--text text--darken-2"
										style="white-space:nowrap;"
									>
										<template v-slot:item.actions="{ item }">
											<v-menu offset-y v-if="item.tipo == 'Reposição' || item.tipo == 'Perda de produção'">
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
														<v-icon>menu</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item v-if="item.tipo == 'Reposição'">
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="excluirReposicao(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-tag-remove</v-icon> Excluir reposição
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item v-if="item.tipo == 'Perda de produção'">
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="excluirPerda(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-tag-remove</v-icon> Excluir perda de produção
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item v-if="item.vincular_serial_estoque == 1">
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="verSeriaisVinculados(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-qrcode-scan</v-icon> Ver seriais
															</v-list-item-title>
														</router-link>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
									</v-data-table>
									<v-flex xs12 class="text-end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
													<v-icon>file_download</v-icon>
													<span>Exportar planilha</span>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemDetalhado, 'detalhado')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
														</v-list-item-title>
													</router-link>
												</v-list-item>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros('detalhado')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
														</v-list-item-title>
													</router-link>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-flex>
								</v-card-text>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogReposicao" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Lançar reposição de subproduto</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formReposicao">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Descrição" v-model="cadastroReposicao.desc_subproduto" :rules="rules.inputTextValidate" disabled></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-alert-circle-outline" label="Quantidade a repor" v-model="cadastroReposicao.quantidade" :rules="rules.inputTextValidate" :type="'number'" min="1"></v-text-field>
							</v-col>
							<v-col cols="12" v-if="dadosSubproduto.vincular_serial_estoque == 1">
								<v-textarea prepend-icon="mdi-comment-outline" label="Seriais das Blanks (separados por ponto e vírgula)" v-model="cadastroReposicao.seriais"></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogReposicao = !showDialogReposicao">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitReposicao()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogPerdaProducao" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Lançar perda de produção</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formPerdaProducao">
						<v-row>
							<v-col cols="12">
								<v-autocomplete prepend-icon="mdi-archive-arrow-down-outline" label="Tipo da perda" v-model="cadastroPerdaProducao.id_tipo_perda" :items="data.listagemTiposPerdasProducao" item-text="desc_tipo_perda" item-value="id_tipo_perda" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Descrição" v-model="cadastroPerdaProducao.desc_subproduto" :rules="rules.inputTextValidate" disabled></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-alert-circle-outline" label="Quantidade perdida" v-model="cadastroPerdaProducao.quantidade" :rules="rules.inputTextValidate" :type="'number'" min="1"></v-text-field>
							</v-col>
							<v-col cols="12" v-if="dadosSubproduto.vincular_serial_estoque == 1">
								<v-textarea prepend-icon="mdi-comment-outline" label="Seriais das Blanks (separados por ponto e vírgula)" v-model="cadastroPerdaProducao.seriais"></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogPerdaProducao = !showDialogPerdaProducao">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitPerdaProducao()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogExcluirReposicao" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Excluir reposição de subproduto</v-card-title>

				<v-card-text>
					<v-alert prominent text type="warning" border="left" icon="mdi-alert-outline">
						Este lançamento de reposição será excluído. Esta ação fará com que o saldo do subproduto vinculado diminua em {{dadosExclusaoReposicao.quantidade}} unidade(s).
					</v-alert>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogExcluirReposicao = !showDialogExcluirReposicao">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitExclusaoReposicao()" :loading="loadingStatus">
						<v-icon left>mdi-delete-outline</v-icon>
						<span>Excluir</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogExcluirPerda" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Excluir perda de produção</v-card-title>

				<v-card-text>
					<v-alert prominent text type="warning" border="left" icon="mdi-alert-outline">
						Este lançamento de perda de produção será excluído. Esta ação fará com que o saldo do subproduto vinculado seja acrescido em {{dadosExclusaoPerda.quantidade}} unidade(s).
					</v-alert>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogExcluirPerda = !showDialogExcluirPerda">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitExclusaoPerda()" :loading="loadingStatus">
						<v-icon left>mdi-delete-outline</v-icon>
						<span>Excluir</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogSeriais" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Seriais vinculados</v-card-title>

				<v-card-text>
					<v-data-table
					    :headers="listagemSeriaisHeaders"
					    :items="dadosSeriais"
					    :footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
						class="grey lighten-4 grey--text text--darken-2 elevation-1"
						style="white-space:nowrap;"
					>
					</v-data-table>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogSeriais = !showDialogSeriais">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitReposicao()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			tablist: ['Consolidado', 'Detalhado'],
			activeTab: null,
			rules: {
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				],
				selectValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null || 'Campo obrigatório'
				]
			},
			data: {
				listagemConsolidado: [{cpf: 'Buscando...'}],
				listagemDetalhado: [{cnpj: 'Buscando...'}],
				listagemProdutos: [{desc_produto: 'Buscando...'}],
				listagemTiposPerdasProducao: [{desc_tipo_perda: 'Buscando...'}],
			},
			filters: {
				desc_produto: '',
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,

			showDialogReposicao: false,
			dadosSubproduto: {},
			cadastroReposicao: {
				id_subproduto: 0,
				desc_subproduto: 0,
				quantidade: 0,
				seriais: ''
			},

			showDialogExcluirReposicao: false,
			dadosExclusaoReposicao: {},

			showDialogExcluirPerda: false,
			dadosExclusaoPerda: {},

			showDialogSeriais: false,
			dadosSeriais: {},
			listagemSeriaisHeaders: [
				{ text: 'ID Reposição/Perda', value: 'id_reposicao_perda', class: 'blue darken-4 white--text' },
				{ text: 'Serial', value: 'serial', class: 'blue darken-4 white--text' },
			],

			showDialogPerdaProducao: false,
			cadastroPerdaProducao: {
				id_subproduto: 0,
				desc_subproduto: 0,
				quantidade: 0,
				seriais: ''
			}
		}
	},
	methods: {
		verSeriaisVinculados(dados) {
			this.dadosSeriais = [{serial: 'Nenhum serial vinculado'}];
			if (dados.seriais) {
				this.dadosSeriais = [];
				dados.seriais.split(';').forEach((item, key) => {
					this.dadosSeriais.push({
						id_reposicao_perda: dados.id_venda_reposicao_perda,
						serial: item
					});
				});
			}
			
			this.showDialogSeriais = true;
		},
		excluirPerda(dados) {
			this.dadosExclusaoPerda = {...dados};
			this.showDialogExcluirPerda = true;
		},
		submitExclusaoPerda() {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosExclusaoPerda;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
                'estoque/excluir_perda_producao',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogExcluirPerda = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		excluirReposicao(dados) {
			this.dadosExclusaoReposicao = {...dados};
			this.showDialogExcluirReposicao = true;
		},
		submitExclusaoReposicao() {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosExclusaoReposicao;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
                'estoque/excluir_reposicao',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogExcluirReposicao = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		lancarPerdas(item) {
			this.dadosSubproduto = {...item};
			this.cadastroPerdaProducao.id_subproduto = item.id_subproduto;
			this.cadastroPerdaProducao.desc_subproduto = item.desc_subproduto;
			this.showDialogPerdaProducao = true;
		},
		submitPerdaProducao() {
			if (this.$refs.formPerdaProducao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.cadastroPerdaProducao;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'estoque/lancar_perda_producao',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogPerdaProducao = false;

	                        		thisEl.cadastroPerdaProducao = {
										id_subproduto: 0,
										desc_subproduto: 0,
										quantidade: 0,
										seriais: ''
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		lancarReposicao(item) {
			this.dadosSubproduto = {...item};
			this.cadastroReposicao.id_subproduto = item.id_subproduto;
			this.cadastroReposicao.desc_subproduto = item.desc_subproduto;
			this.showDialogReposicao = true;
		},
		submitReposicao() {
			if (this.$refs.formReposicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.cadastroReposicao;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'estoque/lancar_reposicao',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogReposicao = false;

	                        		thisEl.cadastroReposicao = {
										id_subproduto: 0,
										desc_subproduto: 0,
										quantidade: 0,
										seriais: ''
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		exportarListagemFiltros(tipo) {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			dataToSend.tipo = tipo;
			
			thisEl.$http.post(//requisição ajax
                'estoque/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'estoque_' + tipo, []);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral(dadosListagem, tipo) {
			let dados = {};

			dados.headers = Object.keys(dadosListagem[0]).map((item) => {
				return {name: item};
			});

			dados.values = dadosListagem;
			
			this.exportXlsAjax(dados, 'estoque_' + tipo, []);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'estoque/listagem_estoque',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemConsolidado = r.body.listagemConsolidado;
	                	thisEl.data.listagemDetalhado = r.body.listagemDetalhado;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'estoque/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemProdutos = r.body.listagemProdutos;
	                	thisEl.data.listagemTiposPerdasProducao = r.body.listagemTiposPerdasProducao;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		}
	},
	computed: {
		listagemHeadersConsolidado() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Subproduto',
					value: 'id_subproduto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'id_subproduto',
					value: 'desc_subproduto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Saldo',
					value: 'saldo',
					class: 'blue darken-4 white--text'
				}
			];
		},
		listagemHeadersDetalhado() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'Ação',
					value: 'acao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Tipo',
					value: 'tipo',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'ID Venda/Reposição/Perda',
					value: 'id_venda_reposicao_perda',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'ID Subproduto',
					value: 'id_subproduto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'id_subproduto',
					value: 'desc_subproduto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Quantidade',
					value: 'quantidade',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'ID Produto',
					value: 'id_produto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Produto',
					value: 'desc_produto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data',
					value: 'data',
					class: 'blue darken-4 white--text'
				}
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
