<template>
	<div class="contas_a_pagar">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Contas a Pagar</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
				<v-dialog scrollable v-model="showDialogCadastro" max-width="700">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
							<v-icon left>add</v-icon>
							<span>Nova Conta</span>
						</v-btn>
					</template>
					<v-card height="fit-content">
						<v-system-bar color="blue darken-4"></v-system-bar>

						<v-card-title class="headline grey--text text--darken-2">Cadastrar nova conta a pagar</v-card-title>

						<v-card-text>
							<v-form class="my-3" ref="formCadastramento" enctype="multipart/form-data" method="post">
								<v-row>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-file-delimited-outline" label="Tipo Conta" v-model="cadastro.id_tipo" :items="data.listagemTiposConta" item-text="desc_tipo" item-value="id_tipo" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-office-building" label="Subtipo" v-model="cadastro.id_subtipo" :items="listagemSubtiposDoTipoSelecionado" item-text="desc_subtipo" item-value="id_subtipo" :rules="rules.selectValidate" clearable v-if="cadastro.id_tipo == 1"></v-autocomplete>
										<v-autocomplete prepend-icon="mdi-account-tie" label="Colaborador" v-model="cadastro.id_colaborador" :items="data.listagemColaboradores" item-text="nome" item-value="id_colaborador" :rules="rules.selectValidate" clearable v-else-if="cadastro.id_tipo == 2" @change="set_valor_salario_colaborador_selecionado()"></v-autocomplete>
										<v-autocomplete prepend-icon="mdi-account-tie" label="Colaborador" v-model="cadastro.id_colaborador" :items="data.listagemColaboradores" item-text="nome" item-value="id_colaborador" :rules="rules.selectValidate" clearable v-else-if="cadastro.id_tipo == 3"></v-autocomplete>
										<v-autocomplete prepend-icon="mdi-account-tie" label="Colaborador" v-model="cadastro.id_colaborador" :items="data.listagemColaboradores" item-text="nome" item-value="id_colaborador" :rules="rules.selectValidate" clearable v-else-if="cadastro.id_tipo == 4"></v-autocomplete>
										<v-autocomplete prepend-icon="mdi-clipboard-text" label="" clearable disabled v-else></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-clipboard-text" label="Descritivo" v-model="cadastro.descritivo" counter="100" :rules="rules.inputTextValidateDescritivo"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-office-building" label="Unidade Operacional" v-model="cadastro.id_unidade_operacional" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="id_unidade" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6" v-if="cadastro.id_tipo == 3">
										<v-text-field prepend-icon="mdi-counter" label="Código Infração" v-model="cadastro.codigo_infracao" counter="20" :rules="rules.inputTextValidate"></v-text-field>
									</v-col>
									<v-col cols="6" v-if="cadastro.id_tipo == 3">
										<v-menu ref="calendarInfracao" v-model="menuOpenCodigoInfracao" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="vencimentoToBRInfracao" label="Data Infração" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
											</template>
											<v-date-picker v-model="cadastro.data_infracao" no-title scrollable locale="pt-br">
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="menuOpenCodigoInfracao = false">Cancelar</v-btn>
												<v-btn text color="primary" @click="$refs.calendarInfracao.save(cadastro.data_infracao)">OK</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-currency-brl" label="Valor" v-model="cadastro.valor" :type="'number'" step="0.01" :rules="rules.inputTextValidate"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-menu ref="calendar" v-model="menuOpen" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="vencimentoToBR" label="Vencimento" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
											</template>
											<v-date-picker v-model="cadastro.vencimento" no-title scrollable locale="pt-br">
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="menuOpen = false">Cancelar</v-btn>
												<v-btn text color="primary" @click="$refs.calendar.save(cadastro.vencimento)">OK</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="12">
										<v-file-input accept=".jpg, .jpeg, .png, .pdf" label="Anexar arquivo de cobrança (.jpg, .jpeg, .png, .pdf)" v-model="fileNameholder" show-size @change="fileSelected" prepend-icon="insert_drive_file"></v-file-input>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions class="my-5">
							<v-btn depressed dark color="blue darken-4" @click="showDialogCadastro = !showDialogCadastro">
								<v-icon left>close</v-icon>
								<span>Cancelar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="submitCadastro" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar</span>
							</v-btn>
						</v-card-actions>

						<v-system-bar color="blue-grey darken-1"></v-system-bar>
					</v-card>
				</v-dialog>
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pr-3">
						<h5>Filtros Gerais</h5>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Status" v-model="filters.desc_status" :items="data.listagemStatus" item-text="desc_status" item-value="desc_status" outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Status Vencimento" v-model="filters.desc_pagamento_atrasado" :items="data.listagemStatusVencimento" item-text="desc_pagamento_atrasado" item-value="desc_pagamento_atrasado" outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Tipo" v-model="filters.desc_tipo" :items="data.listagemTiposConta" item-text="desc_tipo" item-value="desc_tipo" outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Subtipo / Denominação" v-model="filters.denominacao" :items="data.listagemSubTiposConta" item-text="desc_subtipo" item-value="desc_subtipo" outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Denominação" v-model="filters.denominacao" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="CPF" v-model="filters.cpf" outlined dense counter clearable maxlength="11"></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Descritivo" v-model="filters.descritivo" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Descritivo Pagamento" v-model="filters.descritivo_pagamento" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Unidade Operacional" v-model="filters.nome_fantasia" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="nome_fantasia" outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-menu ref="menu" v-model="menuOpenFiltroVencimento" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="vencimentoToBRFiltroVencimento" label="Vencimento" v-bind="attrs"v-on="on" readonly outlined dense clearable></v-text-field>
							</template>
							<v-date-picker v-model="filters.vencimento" no-title scrollable locale="pt-br">
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menuOpenFiltroVencimento = false">Cancelar</v-btn>
								<v-btn text color="primary" @click="$refs.menu.save(filters.vencimento)">OK</v-btn>
							</v-date-picker>
						</v-menu>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-menu ref="menu" v-model="menuOpenFiltroPagamento" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="vencimentoToBRFiltroPagamento" label="Pagamento" v-bind="attrs"v-on="on" readonly outlined dense clearable></v-text-field>
							</template>
							<v-date-picker v-model="filters.pagamento" no-title scrollable locale="pt-br">
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menuOpenFiltroPagamento = false">Cancelar</v-btn>
								<v-btn text color="primary" @click="$refs.menu.save(filters.pagamento)">OK</v-btn>
							</v-date-picker>
						</v-menu>
					</v-flex>

					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-data-table
					:headers="listagemHeaders"
					:items="data.listagemContas"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.actions="{ item }">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
									<v-icon>menu</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item v-if="item.id_tipo == 3">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="verDetalhesConta(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-mail</v-icon> Ver detalhes
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 1">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="darBaixaConta(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-check-all</v-icon> Dar baixa
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 1">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="editarConta(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar conta
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 1">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="excluirConta(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-comment-remove-outline</v-icon> Excluir conta
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.arquivo_cobranca != null">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="abrirArquivo(item.arquivo_cobranca)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-download</v-icon> Ver arquivo de cobrança
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 2 && item.arquivo_comprovante != null">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="abrirArquivoComprovante(item.arquivo_comprovante)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-download</v-icon> Ver comprovante de pagamento
										</v-list-item-title>
									</router-link>
								</v-list-item>
							</v-list>
						</v-menu>
					</template>
					<template v-slot:item.desc_status="{ item }">
						<v-chip class="ma-2 white--text text--white" color="light-blue" v-if="item.id_status == 1">{{item.desc_status}}</v-chip>
						<v-chip class="ma-2 white--text text--white" color="success" v-if="item.id_status == 2">{{item.desc_status}}</v-chip>
					</template>
					<template v-slot:item.desc_pagamento_atrasado="{ item }">
						<v-chip class="ma-2 white--text text--white" color="light-blue" v-if="item.pagamento_atrasado == 'false'">{{item.desc_pagamento_atrasado}}</v-chip>
						<v-chip class="ma-2 white--text text--white" color="orange darken-1" v-else-if="item.pagamento_atrasado == 'truefalse'">{{item.desc_pagamento_atrasado}}</v-chip>
						<v-chip class="ma-2 white--text text--white" color="red darken-2" v-else>{{item.desc_pagamento_atrasado}}</v-chip>
					</template>
					<template v-slot:item.valor="{ item }">
						R$ {{v_number_format(item.valor, 2, ',', '.')}}
					</template>
					<template v-slot:item.vencimento="{ item }">
						{{ (item.vencimento) ? new Date(item.vencimento).toLocaleDateString("pt-BR") : '' }}
					</template>
					<template v-slot:item.data_pagamento="{ item }">
						{{ (item.data_pagamento) ? new Date(item.data_pagamento).toLocaleDateString("pt-BR") : '' }}
					</template>
					<template v-slot:item.dt_criacao="{ item }">
						{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
					</template>
					<template v-slot:item.dt_alteracao="{ item }">
						{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
					</template>
				</v-data-table>
				<v-flex xs12 class="text-end">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
								<v-icon>file_download</v-icon>
								<span>Exportar planilha</span>
							</v-btn>
						</template>
						<v-list>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemContas)">
										<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
									</v-list-item-title>
								</router-link>
							</v-list-item>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
									</v-list-item-title>
								</router-link>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogDados" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Detalhes Conta</v-card-title>

				<v-card-text>
					<v-row v-if="dadosConta.id_tipo == 3">
						<v-col cols="6">
							<v-text-field prepend-icon="mdi-counter" label="Código Infração" v-model="dadosConta.codigo_infracao" disabled></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field prepend-icon="mdi-calendar" label="Data Infração" v-model="dadosConta.data_infracao" disabled></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogDados = !showDialogDados">
						<v-icon left>close</v-icon>
						<span>Fechar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogEdit" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar conta a pagar</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formEdicao" enctype="multipart/form-data" method="post">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-file-delimited-outline" label="Tipo Conta" :value="descricaoTipoConta(dadosEdicao.id_tipo)" disabled></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-office-building" label="Subtipo" :value="dadosEdicao.denominacao" disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-clipboard-text" label="Descritivo" v-model="dadosEdicao.descritivo" counter="100" :rules="rules.inputTextValidateDescritivo"></v-text-field>
							</v-col>
							<v-col cols="6" v-if="dadosEdicao.id_tipo == 3">
								<v-text-field prepend-icon="mdi-counter" label="Código Infração" v-model="dadosEdicao.codigo_infracao" counter="20" :rules="rules.inputTextValidate"></v-text-field>
							</v-col>
							<v-col cols="6" v-if="dadosEdicao.id_tipo == 3">
								<v-menu ref="calendarEdicaoInfracao" v-model="menuOpenEdicaoCodigoInfracao" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="vencimentoToBREdicaoInfracao" label="Data Infração" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosEdicao.data_infracao" no-title scrollable locale="pt-br">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenEdicaoCodigoInfracao = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.calendarEdicaoInfracao.save(dadosEdicao.data_infracao)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-currency-brl" label="Valor" v-model="dadosEdicao.valor" :type="'number'" step="0.01" :rules="rules.inputTextValidate"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-menu ref="calendarEdicao" v-model="menuOpenEdicao" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="vencimentoToBREdicao" label="Vencimento" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosEdicao.vencimento" no-title scrollable locale="pt-br">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenEdicao = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.calendarEdicao.save(dadosEdicao.vencimento)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12">
								<v-file-input accept=".jpg, .jpeg, .png, .pdf" label="Atualizar arquivo de cobrança (.jpg, .jpeg, .png, .pdf)" v-model="fileNameholder" show-size @change="fileSelectedEdicao" prepend-icon="insert_drive_file"></v-file-input>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEdit = !showDialogEdit">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogExcluir" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Excluir Conta</v-card-title>

				<v-card-text>
					<v-alert prominent text type="warning" border="left" icon="mdi-alert-outline">
						A conta será excluída. Esta ação não poderá ser desfeita.
					</v-alert>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogExcluir = !showDialogExcluir">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitExclusao()" :loading="loadingStatus">
						<v-icon left>mdi-comment-remove</v-icon>
						<span>Excluir</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogBaixa" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Dar Baixa na Conta</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formPagamento" enctype="multipart/form-data" method="post">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-clipboard-text" label="Descritivo" v-model="dadosBaixa.descritivo_pagamento" counter="100" :rules="rules.inputTextValidateDescritivo"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-menu ref="menu" v-model="menuOpenBaixa" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="data_pagamentoToBR" label="Data Pagamento" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosBaixa.data_pagamento" no-title scrollable locale="pt-br">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenBaixa = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.menu.save(dadosBaixa.data_pagamento)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12">
								<v-file-input accept=".jpg, .jpeg, .png, .pdf" label="Anexar arquivo de comprovante (.jpg, .jpeg, .png, .pdf)" v-model="fileNameholder" show-size @change="fileSelectedBaixa" prepend-icon="insert_drive_file"></v-file-input>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogBaixa = !showDialogBaixa">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitBaixa()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Registrar Pagamento</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario')),
				id_usuario: Number(localStorage.getItem('id_usuario'))
			},
			cadastro: {
				id_tipo: 0,
				id_subtipo: 0,
				id_colaborador: 0,
				id_unidade_operacional:0,
				descritivo: '',
				valor: 0,
				vencimento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				arquivo_cobranca: null,
				codigo_infracao: '',
				data_infracao: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
			},
			rules: {
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				],
				selectValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null || 'Campo obrigatório'
				],
				fileValidate: [
					v => !!v || 'A evidência é obrigatória',
					v => (v && v.size > 0) || 'A evidência é obrigatória',
				],
				inputTextValidateDescritivo: [
					v => v.length <= 100 || 'Máximo 100 caracteres'
				],
			},
			data: {
				listagemContas: [{id_conta: 'Buscando...'}],
				listagemTiposConta: [{desc_tipo: 'Buscando...'}],
				listagemSubTiposConta: [{desc_subtipo: 'Buscando...'}],
				listagemColaboradores: [{nome: 'Buscando...'}],
				listagemStatus: [{desc_status: 'Buscando...'}],
				listagemStatusVencimento: [{desc_pagamento_atrasado: 'Buscando...'}],
				listagemUnidades: [{nome_fantasia: 'Buscando...'}],
			},
			filters: {
				desc_status: '',
				desc_pagamento_atrasado: '',
				cpf: '',
				descritivo: '',
				nome_fantasia: '',
				descritivo_pagamento: '',
				denominacao: '',
				vencimento: null
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,

			dadosConta: {},
			showDialogDados: false,
			
			dadosEdicao: {},
			showDialogEdit: false,

			dadosExclusao: {},
			showDialogExcluir: false,

			dadosBaixa: {},
			showDialogBaixa: false,

			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			menuOpen: false,
			menuOpenCodigoInfracao: false,
			menuOpenEdicao: false,
			menuOpenEdicaoCodigoInfracao: false,
			menuOpenBaixa: false,
			menuOpenFiltroPagamento: false,
			menuOpenFiltroVencimento: false,

			fileNameholder: null
		}
	},
	watch: {
		
	},
	methods: {
		abrirArquivo(arquivo) {
			window.open(this.$http.options.root + 'resources/contas_a_pagar/arquivos_cobranca/' + arquivo, '_blank');
		},
		abrirArquivoComprovante(arquivo) {
			window.open(this.$http.options.root + 'resources/contas_a_pagar/arquivos_comprovante/' + arquivo, '_blank');
		},
		fileSelected($event){
			this.cadastro.arquivo_cobranca = $event;
        },
        fileSelectedEdicao($event){
			this.dadosEdicao.arquivo_cobranca = $event;
        },
        fileSelectedBaixa($event){
			this.dadosBaixa.arquivo_comprovante = $event;
        },
		descricaoTipoConta(id_tipo) {
			let thisEl = this;
			let desc_tipo = '';

			this.data.listagemTiposConta.forEach(function(item, key){
				if (item.id_tipo == Number(id_tipo)) {
					desc_tipo = item.desc_tipo;
				}
			});

			return desc_tipo;
		},
		set_valor_salario_colaborador_selecionado(edicao = false) {
			let thisEl = this;
			let valor = 0;
			let objAlvo = thisEl.cadastro;

			if (edicao) {
				objAlvo = thisEl.dadosEdicao;
			}
			
			this.data.listagemColaboradores.forEach(function(item, key){
				if (item.id_colaborador == Number(objAlvo.id_colaborador)) {
					valor = item.salario;
				}
			});

			objAlvo.valor = valor;
		},
		exportarListagemFiltros() {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			
			thisEl.$http.post(//requisição ajax
                'contas_a_pagar/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'contas_a_pagar', ['id_conta', 'id_tipo', 'valor']);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral(dadosListagem) {
			let dados = {};

			dados.headers = Object.keys(dadosListagem[0]).map((item) => {
				return {name: item};
			});

			dados.values = dadosListagem;
			
			this.exportXlsAjax(dados, 'contas_a_pagar', []);
		},
		exportXlsAjax(dados, filename, numericHeaders){

			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		submitCadastro () {
			if (this.$refs.formCadastramento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				let formData = new FormData();

				formData.append('token', localStorage.getItem('token'));
				formData.append('id_tipo', this.cadastro.id_tipo);
				formData.append('id_subtipo', this.cadastro.id_subtipo);
				formData.append('id_colaborador', this.cadastro.id_colaborador);
				formData.append('id_unidade_operacional', this.cadastro.id_unidade_operacional);
				formData.append('descritivo', this.cadastro.descritivo);
				formData.append('valor', this.cadastro.valor);
				formData.append('vencimento', this.cadastro.vencimento);
				formData.append('arquivo_cobranca', this.cadastro.arquivo_cobranca);
				formData.append('codigo_infracao', this.cadastro.codigo_infracao);
				formData.append('data_infracao', this.cadastro.data_infracao);

				thisEl.$http.post(//requisição ajax
                    'contas_a_pagar/criar_conta',
                    formData,
                    {
                    	headers: {'Content-Type': 'multipart/form-data'}
                    }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogCadastro = false;

	                        		thisEl.fileNameholder = null;

	                        		thisEl.cadastro = {
										id_tipo: 0,
										id_subtipo: 0,
										id_colaborador: 0,
										descritivo: '',
										valor: 0,
										vencimento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
										arquivo_cobranca: null
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		verDetalhesConta(dados) {
			console.log(dados);
			this.dadosConta = {...dados};
			this.dadosConta.data_infracao = new Date(dados.data_infracao).toLocaleDateString("pt-BR");
			this.showDialogDados = true;
		},
		excluirConta(dados) {
			this.dadosExclusao = {...dados};
			this.showDialogExcluir = true;
		},
		submitExclusao() {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosExclusao;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
                'contas_a_pagar/excluir_conta',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogExcluir = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		darBaixaConta(dados) {
			this.dadosBaixa = {...dados};
			this.dadosBaixa.descritivo_pagamento = '';
			this.dadosBaixa.data_pagamento = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
			this.showDialogBaixa = true;
		},
		submitBaixa () {
			if (this.$refs.formPagamento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				let formData = new FormData();
				formData.append('token', localStorage.getItem('token'));
				formData.append('id_conta', this.dadosBaixa.id_conta);
				formData.append('descritivo_pagamento', this.dadosBaixa.descritivo_pagamento);
				formData.append('data_pagamento', this.dadosBaixa.data_pagamento);
				formData.append('arquivo_comprovante', this.dadosBaixa.arquivo_comprovante);

				thisEl.$http.post(//requisição ajax
                    'contas_a_pagar/efetuar_baixa_conta',
                    formData,
                    {
                    	headers: {'Content-Type': 'multipart/form-data'}
                    }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogBaixa = false;

	                        		thisEl.fileNameholder = null;
	                        	}, 1000);

                            	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		editarConta (dados) {
			this.dadosEdicao = {...dados};
			this.showDialogEdit = true;
		},
		submitEdicao () {
			if (this.$refs.formEdicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				let formData = new FormData();
				formData.append('token', localStorage.getItem('token'));
				formData.append('id_conta', this.dadosEdicao.id_conta);
				formData.append('descritivo', this.dadosEdicao.descritivo);
				formData.append('valor', this.dadosEdicao.valor);
				formData.append('vencimento', this.dadosEdicao.vencimento);
				formData.append('arquivo_cobranca', this.dadosEdicao.arquivo_cobranca);
				formData.append('codigo_infracao', this.dadosEdicao.codigo_infracao);
				formData.append('data_infracao', this.dadosEdicao.data_infracao);

				thisEl.$http.post(//requisição ajax
                    'contas_a_pagar/atualizar_conta',
                    formData,
                    {
                    	headers: {'Content-Type': 'multipart/form-data'}
                    }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogEdit = false;

	                        		thisEl.fileNameholder = null;
	                        	}, 1000);

                            	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		limparFiltro() {
			var thisEl = this;
			
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		v_number_format (number, decimals, dec_point, thousands_sep) {
			return number_format(number, decimals, dec_point, thousands_sep);
		},
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'contas_a_pagar/listagem_contas',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemContas = r.body.listagemContas;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'contas_a_pagar/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemTiposConta = r.body.listagemTiposContaAPagar
	                	thisEl.data.listagemSubTiposConta = r.body.listagemSubTiposContaAPagar;
						thisEl.data.listagemColaboradores = r.body.listagemColaboradores;
						thisEl.data.listagemStatus = r.body.listagemStatus;
						thisEl.data.listagemStatusVencimento = r.body.listagemStatusVencimento;
						thisEl.data.listagemUnidades = r.body.listagemUnidades;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		formatDate (date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		}
	},
	computed: {
		vencimentoToBR() {
			return this.formatDate(this.cadastro.vencimento)
		},
		vencimentoToBRInfracao() {
			return this.formatDate(this.cadastro.data_infracao)
		},
		vencimentoToBREdicao(){
			return this.formatDate(this.dadosEdicao.vencimento.substr(0, 10))
		},
		vencimentoToBREdicaoInfracao() {
			return this.formatDate(this.dadosEdicao.data_infracao.substr(0, 10))
		},
		data_pagamentoToBR(){
			return this.formatDate(this.dadosBaixa.data_pagamento.substr(0, 10))
		},
		vencimentoToBRFiltroVencimento: {
			get: function() {
				let data = null;

				if (this.filters.vencimento) {
					data = this.formatDate(this.filters.vencimento.substr(0, 10));
				}

				return data;
			},
			set: function(newValue) {
				this.filters.vencimento = newValue;
			}
		},
		vencimentoToBRFiltroPagamento: {
			get: function() {
				let data = null;

				if (this.filters.pagamento) {
					data = this.formatDate(this.filters.pagamento.substr(0, 10));
				}

				return data;
			},
			set: function(newValue) {
				this.filters.pagamento = newValue;
			}
		},
		listagemSubtiposDoTipoSelecionado() {
			let thisEl = this;
			let listaSubtipos = [];
			
			this.data.listagemSubTiposConta.forEach(function(item, key){
				if (item.id_tipo == Number(thisEl.cadastro.id_tipo)) {
					listaSubtipos.push(item);
				}
			});

			if (listaSubtipos.length == 1) {
				thisEl.cadastro.id_subtipo = listaSubtipos[0].id_subtipo;
			} else {
				thisEl.cadastro.id_subtipo = 0;
			}

			return listaSubtipos;
		},
		listagemHeaders() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Conta',
					value: 'id_conta',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Status',
					value: 'desc_status',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_status) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_status.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Status Vencimento',
					value: 'desc_pagamento_atrasado',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_pagamento_atrasado) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_pagamento_atrasado.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Vencimento',
					value: 'vencimento',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.vencimento) {
							return true;
						}

						return (value != null && new Date(value).toLocaleDateString("pt-BR") == this.formatDate(this.filters.vencimento)) ? true : false;
					}
				},
				{
					text: 'Unidade Operacional',
					value: 'nome_fantasia',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome_fantasia) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome_fantasia.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Pagamento',
					value: 'data_pagamento',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.pagamento) {
							return true;
						}

						return (value != null && new Date(value).toLocaleDateString("pt-BR") == this.formatDate(this.filters.pagamento)) ? true : false;
					}
				},
				{
					text: 'Valor',
					value: 'valor',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Tipo',
					value: 'desc_tipo',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_tipo) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_tipo.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Denominação',
					value: 'denominacao',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.denominacao) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.denominacao.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'CPF',
					value: 'cpf',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cpf) {
							return true;
						}

						return (value != null && value.toUpperCase().indexOf(this.filters.cpf.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Descritivo',
					value: 'descritivo',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.descritivo) {
							return true;
						}

						return (value != null && value.toUpperCase().indexOf(this.filters.descritivo.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Descritivo Pagamento',
					value: 'descritivo_pagamento',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.descritivo_pagamento) {
							return true;
						}

						return (value != null && value.toUpperCase().indexOf(this.filters.descritivo_pagamento.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
