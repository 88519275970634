<template>
	<div class="administrativo_colaboradores">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Colaboradores</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
				<v-dialog scrollable v-model="showDialogCadastro" max-width="700">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
							<v-icon left>add</v-icon>
							<span>Novo Colaborador</span>
						</v-btn>
					</template>
					<v-card height="fit-content">
						<v-system-bar color="blue darken-4"></v-system-bar>

						<v-card-title class="headline grey--text text--darken-2">Cadastrar novo Colaborador</v-card-title>

						<v-card-text>
							<v-form class="my-3" ref="formCadastramento">
								<v-row>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-card-text-outline" label="Nome completo" v-model="cadastro.nome" :rules="rules.inputTextValidate" counter maxlength="150"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-menu ref="calendarNascimento" v-model="menuOpenNascimento" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="data_nascimentoToBR" label="Data Nascimento" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
											</template>
											<v-date-picker v-model="cadastro.data_nascimento" no-title scrollable locale="pt-br">
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="menuOpenNascimento = false">Cancelar</v-btn>
												<v-btn text color="primary" @click="$refs.calendarNascimento.save(cadastro.data_nascimento)">OK</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-phone" label="Telefone (ex.: 11953534747)" v-model="cadastro.telefone" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-phone" label="Telefone Emergência (ex.: 11953534747)" v-model="cadastro.telefone_emergencia" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="cadastro.endereco" :rules="rules.inputTextValidate" counter maxlength="200"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-map-check" label="Bairro" v-model="cadastro.bairro" counter maxlength="50"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-map-marker-circle" label="CEP" v-model="cadastro.cep" :rules="rules.inputCEP" counter maxlength="8"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-smart-card" label="RG" v-model="cadastro.rg" :rules="rules.inputRg" counter maxlength="9"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-smart-card-outline" label="CPF" v-model="cadastro.cpf" :rules="rules.inputCpf" counter maxlength="11"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-office-building" label="Unidade Operacional" v-model="cadastro.id_unidade" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="id_unidade" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-account-tie" label="Cargo Operacional" v-model="cadastro.id_cargo_colaborador" :items="data.listagemCargosColaboradores" item-text="desc_cargo_colaborador" item-value="id_cargo_colaborador" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-menu ref="calendarDataEntrada" v-model="menuOpenDataEntrada" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="data_entradaToBR" label="Data Entrada" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
											</template>
											<v-date-picker v-model="cadastro.data_entrada" no-title scrollable locale="pt-br">
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="menuOpenDataEntrada = false">Cancelar</v-btn>
												<v-btn text color="primary" @click="$refs.calendarDataEntrada.save(cadastro.data_entrada)">OK</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="6">
										<v-menu ref="calendarDataRegistro" v-model="menuOpenDataRegistro" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="data_registroToBR" label="Data Registro" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
											</template>
											<v-date-picker v-model="cadastro.data_registro" no-title scrollable locale="pt-br">
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="menuOpenDataRegistro = false">Cancelar</v-btn>
												<v-btn text color="primary" @click="$refs.calendarDataRegistro.save(cadastro.data_registro)">OK</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-currency-brl" label="Salário" v-model="cadastro.salario" :type="'number'" step="0.01" :rules="rules.inputSalario"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-cash-marker" label="Periculosidade" v-model="cadastro.periculosidade" :type="'number'" step="0.01" :rules="rules.inputSalario"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-credit-card-plus" label="Vale Refeição" v-model="cadastro.vale_refeicao" :type="'number'" step="0.01" :rules="rules.inputSalario"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-credit-card-plus" label="Vale Transporte" v-model="cadastro.vale_transporte" :type="'number'" step="0.01" :rules="rules.inputSalario"></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12">
										<v-select prepend-icon="mdi-account" label="Registrar Login de Acesso?" v-model="cadastro.registrar_login" :items="['Não', 'Sim']"></v-select>
									</v-col>
								</v-row>
								<v-row v-if="cadastro.registrar_login == 'Sim'">
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-account-tie" label="Cargo No Sistema" v-model="cadastro.id_cargo" :items="cargosParaCriacao" item-text="desc_cargo" item-value="id_cargo" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-account-key" label="Username (usado no login)" v-model="cadastro.username" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-autocomplete prepend-icon="mdi-office-building" label="Unidades" v-model="cadastro.ids_unidades" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="id_unidade" clearable multiple></v-autocomplete>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions class="my-5">
							<v-btn depressed dark color="blue darken-4" @click="showDialogCadastro = !showDialogCadastro">
								<v-icon left>close</v-icon>
								<span>Cancelar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="submitCadastro" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar</span>
							</v-btn>
						</v-card-actions>

						<v-system-bar color="blue-grey darken-1"></v-system-bar>
					</v-card>
				</v-dialog>
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pr-3">
						<h5>Filtros Gerais</h5>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="Nome" v-model="filters.nome" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="RG" v-model="filters.rg" outlined dense counter clearable maxlength="9"></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-text-field label="CPF" v-model="filters.cpf" outlined dense counter clearable maxlength="11"></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Cargo" v-model="filters.desc_cargo_colaborador" :items="data.listagemCargosColaboradores" item-text="desc_cargo_colaborador" item-value="desc_cargo_colaborador" outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Unidade Operacional" v-model="filters.unidade_operacional" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="nome_fantasia" outlined dense clearable></v-autocomplete>
					</v-flex>
					
					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-card>
					<v-tabs v-model="activeTab" background-color="grey lighten-4">
						<v-tabs-slider color="indigo darken-4"></v-tabs-slider>
						<v-tab v-for="item in tablist" :key="item" class="indigo--text text--darken-4">{{ item }}</v-tab>
					</v-tabs>

					<v-tabs-items v-model="activeTab">
						<v-tab-item key="Ativos">
							<v-card flat>
								<v-card-text>
									<v-data-table
										:headers="listagemHeaders"
										:items="data.listagemGeral"
										:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
										class="grey lighten-4 grey--text text--darken-2"
										style="white-space:nowrap;"
									>
										<template v-slot:item.actions="{ item }">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
														<v-icon>menu</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item>
														<router-link to="">
															<v-list-item-title class="grey--text text--darken-2" @click="editarColaborador(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar Colaborador
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item v-if="item.status == 'Ativo'">
														<router-link to="">
															<v-list-item-title class="grey--text text--darken-2" @click="confirmaInativacaoColaborador(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-account-cancel</v-icon> Desligar Colaborador
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item v-if="item.status == 'Inativo'">
														<router-link to="">
															<v-list-item-title class="grey--text text--darken-2" @click="confirmaAtivacaoColaborador(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-account-plus</v-icon> Religar Colaborador
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item v-if="item.id_usuario == null">
														<router-link to="">
															<v-list-item-title class="grey--text text--darken-2" @click="cadastrarLogin(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-import</v-icon> Registrar Login de Usuário
															</v-list-item-title>
														</router-link>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
										<template v-slot:item.status="{ item }">
											<v-chip class="ma-2 white--text text--white" :color="item.status == 'Ativo' ? 'success' : 'blue-grey lighten-3'">{{item.status}}</v-chip>
										</template>
										<template v-slot:item.id_usuario="{ item }">
											<v-chip class="ma-2 white--text text--white" :color="item.id_usuario != null ? 'success' : 'info'">{{(item.id_usuario != null) ? 'Sim' : 'Não'}}</v-chip>
										</template>
										<template v-slot:item.rg="{ item }">
											{{ mascara_rg(item.rg) }}
										</template>
										<template v-slot:item.cpf="{ item }">
											{{ mascara_cpf(item.cpf) }}
										</template>
										<template v-slot:item.cep="{ item }">
											{{ mascara_cep(item.cep) }}
										</template>
										<template v-slot:item.telefone="{ item }">
											{{ mascara_telefone(item.telefone) }}
										</template>
										<template v-slot:item.telefone_emergencia="{ item }">
											{{ mascara_telefone(item.telefone_emergencia) }}
										</template>
										<template v-slot:item.salario="{ item }">
											R$ {{v_number_format(item.salario, 2, ',', '.')}}
										</template>
										<template v-slot:item.periculosidade="{ item }">
											R$ {{v_number_format(item.periculosidade, 2, ',', '.')}}
										</template>
										<template v-slot:item.vale_refeicao="{ item }">
											R$ {{v_number_format(item.vale_refeicao, 2, ',', '.')}}
										</template>
										<template v-slot:item.vale_transporte="{ item }">
											R$ {{v_number_format(item.vale_transporte, 2, ',', '.')}}
										</template>
										<template v-slot:item.dt_criacao="{ item }">
											{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
										</template>
										<template v-slot:item.dt_alteracao="{ item }">
											{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
										</template>
									</v-data-table>
									<v-flex xs12 class="text-end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
													<v-icon>file_download</v-icon>
													<span>Exportar planilha</span>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemGeral)">
															<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
														</v-list-item-title>
													</router-link>
												</v-list-item>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros('ativos')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
														</v-list-item-title>
													</router-link>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-flex>
								</v-card-text>
							</v-card>
						</v-tab-item>
						<v-tab-item key="Inativos">
							<v-card flat>
								<v-card-text>
									<v-data-table
										:headers="listagemHeaders"
										:items="data.listagemGeralInativos"
										:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
										class="grey lighten-4 grey--text text--darken-2"
										style="white-space:nowrap;"
									>
										<template v-slot:item.actions="{ item }">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
														<v-icon>menu</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item>
														<router-link to="">
															<v-list-item-title class="grey--text text--darken-2" @click="editarColaborador(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar Colaborador
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item v-if="item.status == 'Ativo'">
														<router-link to="">
															<v-list-item-title class="grey--text text--darken-2" @click="confirmaInativacaoColaborador(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-account-cancel</v-icon> Desligar Colaborador
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item v-if="item.status == 'Inativo'">
														<router-link to="">
															<v-list-item-title class="grey--text text--darken-2" @click="confirmaAtivacaoColaborador(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-account-plus</v-icon> Religar Colaborador
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item v-if="item.id_usuario == null">
														<router-link to="">
															<v-list-item-title class="grey--text text--darken-2" @click="cadastrarLogin(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-import</v-icon> Registrar Login de Usuário
															</v-list-item-title>
														</router-link>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
										<template v-slot:item.status="{ item }">
											<v-chip class="ma-2 white--text text--white" :color="item.status == 'Ativo' ? 'success' : 'blue-grey lighten-3'">{{item.status}}</v-chip>
										</template>
										<template v-slot:item.id_usuario="{ item }">
											<v-chip class="ma-2 white--text text--white" :color="item.id_usuario != null ? 'success' : 'info'">{{(item.id_usuario != null) ? 'Sim' : 'Não'}}</v-chip>
										</template>
										<template v-slot:item.rg="{ item }">
											{{ mascara_rg(item.rg) }}
										</template>
										<template v-slot:item.cpf="{ item }">
											{{ mascara_cpf(item.cpf) }}
										</template>
										<template v-slot:item.cep="{ item }">
											{{ mascara_cep(item.cep) }}
										</template>
										<template v-slot:item.telefone="{ item }">
											{{ mascara_telefone(item.telefone) }}
										</template>
										<template v-slot:item.telefone_emergencia="{ item }">
											{{ mascara_telefone(item.telefone_emergencia) }}
										</template>
										<template v-slot:item.salario="{ item }">
											R$ {{v_number_format(item.salario, 2, ',', '.')}}
										</template>
										<template v-slot:item.periculosidade="{ item }">
											R$ {{v_number_format(item.periculosidade, 2, ',', '.')}}
										</template>
										<template v-slot:item.vale_refeicao="{ item }">
											R$ {{v_number_format(item.vale_refeicao, 2, ',', '.')}}
										</template>
										<template v-slot:item.vale_transporte="{ item }">
											R$ {{v_number_format(item.vale_transporte, 2, ',', '.')}}
										</template>
										<template v-slot:item.dt_criacao="{ item }">
											{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
										</template>
										<template v-slot:item.dt_alteracao="{ item }">
											{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
										</template>
									</v-data-table>
									<v-flex xs12 class="text-end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
													<v-icon>file_download</v-icon>
													<span>Exportar planilha</span>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemGeralInativos)">
															<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
														</v-list-item-title>
													</router-link>
												</v-list-item>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros('inativos')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
														</v-list-item-title>
													</router-link>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-flex>
								</v-card-text>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogEdit" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar Colaborador</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formEdicao">
						<v-alert border="left" colored-border prominent text type="info">
							Caso o colaborador tiver login de acesso ao sistema, também serão atualizados Nome e CPF.
						</v-alert>
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Nome" v-model="dadosEdicao.nome" :rules="rules.inputTextValidate" counter maxlength="150"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-menu ref="calendarNascimentoEdit" v-model="menuOpenNascimentoEdit" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="data_nascimentoToBREdit" label="Data Nascimento" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosEdicao.data_nascimento" no-title scrollable locale="pt-br">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenNascimentoEdit = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.calendarNascimentoEdit.save(dadosEdicao.data_nascimento)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-phone" label="Telefone (ex.: 11953534747)" v-model="dadosEdicao.telefone" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-phone" label="Tel. Emergência (ex.: 11953534747)" v-model="dadosEdicao.telefone_emergencia" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="dadosEdicao.endereco" :rules="rules.inputTextValidate" counter maxlength="200"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-map-check" label="Bairro" v-model="dadosEdicao.bairro" counter maxlength="50"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-map-marker-circle" label="CEP" v-model="dadosEdicao.cep" :rules="rules.inputCEP" counter maxlength="8"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-smart-card" label="RG" v-model="dadosEdicao.rg" :rules="rules.inputRg" counter maxlength="9"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-smart-card-outline" label="CPF" v-model="dadosEdicao.cpf" :rules="rules.inputCpf" counter maxlength="11"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-office-building" label="Unidade Operacional" v-model="dadosEdicao.id_unidade" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="id_unidade" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-account-tie" label="Cargo Operacional" v-model="dadosEdicao.id_cargo_colaborador" :items="data.listagemCargosColaboradores" item-text="desc_cargo_colaborador" item-value="id_cargo_colaborador" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-menu ref="calendarDataEntradaEdit" v-model="menuOpenDataEntradaEdit" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="data_entradaToBREdit" label="Data Entrada" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosEdicao.data_entrada" no-title scrollable locale="pt-br">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenDataEntradaEdit = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.calendarDataEntradaEdit.save(dadosEdicao.data_entrada)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="6">
								<v-menu ref="calendarDataRegistroEdit" v-model="menuOpenDataRegistroEdit" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="data_registroToBREdit" label="Data Registro" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosEdicao.data_registro" no-title scrollable locale="pt-br">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenDataRegistroEdit = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.calendarDataRegistroEdit.save(dadosEdicao.data_registro)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-currency-brl" label="Salário" v-model="dadosEdicao.salario" :type="'number'" step="0.01" :rules="rules.inputSalario"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-cash-marker" label="Periculosidade" v-model="dadosEdicao.periculosidade" :type="'number'" step="0.01" :rules="rules.inputSalario"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-credit-card-plus" label="Vale Refeição" v-model="dadosEdicao.vale_refeicao" :type="'number'" step="0.01" :rules="rules.inputSalario"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-credit-card-plus" label="Vale Transporte" v-model="dadosEdicao.vale_transporte" :type="'number'" step="0.01" :rules="rules.inputSalario"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEdit = !showDialogEdit">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogConfirmInativacao" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Confirma o desligamento deste colaborador?</v-card-title>

				<v-card-text>
					<v-alert border="left" colored-border prominent text type="info">
						Para restringir acesso deste colaborador ao sistema é recomendado desativar seu login de acesso, caso exista.
					</v-alert>
					<v-row>
						<v-col cols="12">
							<v-select prepend-icon="mdi-account" label="Desativar Login de Acesso?" v-model="dadosDesligamento.desativar_login" :items="['Não', 'Sim']"></v-select>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<v-btn depressed dark color="blue darken-4" @click="showDialogConfirmInativacao = !showDialogConfirmInativacao">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="efetuarInativacaoColaborador()" :loading="loadingStatus">
						<v-icon left>mdi-cancel</v-icon>
						<span>Desligar Colaborador</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogConfirmAtivacao" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Confirma religamento deste colaborador?</v-card-title>

				<v-card-text>
					<v-alert border="left" colored-border prominent text type="info">
						Para permitir o acesso deste colaborador ao sistema é recomendado ativar seu login de acesso, caso exista.
					</v-alert>
					<v-row>
						<v-col cols="12">
							<v-select prepend-icon="mdi-account" label="Ativar Login de Acesso?" v-model="dadosAtivacao.ativar_login" :items="['Não', 'Sim']"></v-select>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<v-btn depressed dark color="blue darken-4" @click="showDialogConfirmAtivacao = !showDialogConfirmAtivacao">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="efetuarAtivacaoColaborador()" :loading="loadingStatus">
						<v-icon left>mdi-check-all</v-icon>
						<span>Ativar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogLogin" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Cadastrar login para o colaborador</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formCadastramentoUsuario">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Nome" v-model="dadosLogin.nome" counter maxlength="150" disabled></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-smart-card-outline" label="CPF" v-model="dadosLogin.cpf" counter maxlength="11" disabled></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-account-tie" label="Cargo No Sistema" v-model="dadosLogin.id_cargo" :items="cargosParaCriacao" item-text="desc_cargo" item-value="id_cargo" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-account-key" label="Username (usado no login)" v-model="dadosLogin.username" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-autocomplete prepend-icon="mdi-office-building" label="Unidades" v-model="dadosLogin.ids_unidades" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="id_unidade" clearable multiple></v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions>
					<v-btn depressed dark color="blue darken-4" @click="showDialogLogin = !showDialogLogin">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="efetuarCadastroLoginColaborador()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Cadastrar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario'))
			},
			tablist: ['Ativos', 'Inativos'],
			activeTab: null,
			cadastro: {
				nome: '',
				data_nascimento: '',
				telefone: '',
				telefone_emergencia: '',
				endereco: '',
				rg: '',
				cpf: '',
				data_entrada: '',
				data_registro: '',
				registrar_login: 'Não',
				salario: '0',
				periculosidade: '0',
				vale_refeicao: '0',
				vale_transporte: '0',
				id_cargo: '',
				id_cargo_colaborador: '',
				id_unidade: '',
				username: '',
				ids_unidades: []
			},
			rules: {
				inputRg: [
					v => v != '' || 'Campo obrigatório',
					v => v != null && v.length == 9 || 'Exatamente 9 dígitos, preenchidos com zeros a esquerda'
				],
				inputCpf: [
					v => v != '' || 'Campo obrigatório',
					v => v != null && v.length == 11 || 'Exatamente 11 dígitos, preenchidos com zeros a esquerda',
					v => /^\d+$/.test(v) || 'Somente números'
				],
				inputTelefone: [
					v => !v || v.length >= 10 || 'Telefones com DDD, entre 10 (fixo) e 11 (celular) dígitos',
					v => !v || v.length <= 11 || 'Telefones com DDD, entre 10 (fixo) e 11 (celular) dígitos',
					v => !v || /^\d+$/.test(v) || 'Somente números'
				],
				inputCEP: [
					v => !v || v.length == 8 || 'CEP tem 8 dígitos',
					v => !v || /^\d+$/.test(v) || 'Somente números'
				],
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null && v.length > 0 || 'Campo obrigatório'
				],
				inputSalario: [
					v => !v || Number(v) >= 0 || 'Insira o valor corretamente'
				],
				selectValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null || 'Campo obrigatório'
				]
			},
			data: {
				listagemGeral: [{nome: 'Buscando...'}],
				listagemGeralInativos: [{nome: 'Buscando...'}],
				listagemCargos: [{desc_cargo: 'Buscando...'}],
				listagemCargosColaboradores: [{desc_cargo_colaborador: 'Buscando...'}],
				listagemUnidades: [{nome_fantasia: 'Buscando...'}],
			},
			filters: {
				nome: '',
				rg: '',
				cpf: '',
				desc_cargo_colaborador: '',
				unidade_operacional: ''
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,

			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			menuOpenNascimento: false,
			menuOpenNascimentoEdit: false,

			menuOpenDataEntrada: false,
			menuOpenDataEntradaEdit: false,

			menuOpenDataRegistro: false,
			menuOpenDataRegistroEdit: false,
			
			dadosEdicao: {},
			showDialogEdit: false,

			dadosDesligamento: {},
			showDialogConfirmInativacao: false,

			dadosAtivacao: {},
			showDialogConfirmAtivacao: false,

			dadosLogin: {
				id_colaborador: 0,
				nome: '',
				cpf: '',
				id_cargo: '',
				username: '',
				ids_unidades: []
			},
			showDialogLogin: false
		}
	},
	methods: {
		exportarListagemFiltros(tipo) {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			dataToSend.ativo = 1;
			if (tipo == 'inativos') {
				dataToSend.ativo = 0;
			}
			
			thisEl.$http.post(//requisição ajax
                'colaboradores/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'colaboradores', ['id_colaborador', 'id_cargo_colaborador', 'salario', 'periculosidade', 'vale_refeicao', 'vale_transporte', 'id_usuario', 'id_usuario_criador', 'id_usuario_alterador']);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral(dadosListagem) {
			let dados = {};

			dados.headers = Object.keys(dadosListagem[0]).map((item) => {
				return {name: item};
			});

			dados.values = dadosListagem;
			
			this.exportXlsAjax(dados, 'colaboradores', ['id_colaborador', 'id_cargo_colaborador', 'salario', 'periculosidade', 'vale_refeicao', 'vale_transporte', 'id_usuario', 'id_usuario_criador', 'id_usuario_alterador']);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		submitCadastro () {
			if (this.$refs.formCadastramento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.cadastro;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'colaboradores/criar_colaborador',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogCadastro = false;

	                        		thisEl.cadastro = {
										nome: '',
										data_nascimento: '',
										telefone: '',
										telefone_emergencia: '',
										endereco: '',
										rg: '',
										cpf: '',
										data_entrada: '',
										data_registro: '',
										registrar_login: 'Não',
										salario: '0',
										id_cargo: '',
										id_cargo_colaborador: '',
										id_unidade_operacional: '',
										username: '',
										ids_unidades: []
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		editarColaborador (dados) {
			this.dadosEdicao = {...dados};
			this.showDialogEdit = true;
		},
		submitEdicao () {
			if (this.$refs.formEdicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.dadosEdicao;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'colaboradores/atualizar_colaborador',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogEdit = false;

	                        		thisEl.dadosEdicao = {};
	                        	}, 1000);

                            	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		confirmaInativacaoColaborador (dados) {
			this.dadosDesligamento = {...dados};
			this.dadosDesligamento.desativar_login = 'Sim';
			this.showDialogConfirmInativacao = true;
		},
		efetuarInativacaoColaborador () {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosDesligamento;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
	            'colaboradores/inativar_colaborador',
	            dataToSend,
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.loadingStatus = false;
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogConfirmInativacao = false;

                        		thisEl.dadosDesligamento = {};
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		confirmaAtivacaoColaborador (dados) {
			this.dadosAtivacao = {...dados};
			this.dadosAtivacao.ativar_login = 'Sim';
			this.showDialogConfirmAtivacao = true;
		},
		efetuarAtivacaoColaborador () {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosAtivacao;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
	            'colaboradores/ativar_colaborador',
	            dataToSend,
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.loadingStatus = false;
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogConfirmAtivacao = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		cadastrarLogin (dados) {
			this.dadosLogin.id_colaborador = dados.id_colaborador;
			this.dadosLogin.nome = dados.nome;
			this.dadosLogin.cpf = dados.cpf;
			this.dadosLogin.username = dados.nome.split(' ')[0].toLowerCase() + '.' + dados.nome.split(' ')[dados.nome.split(' ').length - 1].toLowerCase();

			this.showDialogLogin = true;
		},
		efetuarCadastroLoginColaborador () {
			if (this.$refs.formCadastramentoUsuario.validate()) {
			
				this.loadingStatus = true;

				var thisEl = this;

				var dataToSend = this.dadosLogin;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
		            'colaboradores/cadastrar_login_colaborador',
		            dataToSend,
		            { emulateJSON: true }
		        ).then(
		            function(r){//sucesso
		                if (r.body.token == false) {
		                	thisEl.loadingStatus = false;
		                	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
		                		thisEl.$router.push('/logout');
		                	}, 2000);
		                } else {
		                	if (r.body.success) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
	                        	thisEl.snackbarShower = true;

	                        	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogLogin = false;

	                        		thisEl.dadosLogin = {
										nome: '',
										cpf: '',
										id_cargo: '',
										username: '',
										ids_unidades: []
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
	                        } else {
	                        	thisEl.loadingStatus = false;
	                        	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
	                        }
		                }
					},
		            function(error){//erro na requisição, por exemplo 404
		                thisEl.loadingStatus = false;
		            	thisEl.snackbarColor = 'red';
		  				thisEl.snackbarText = 'Houve um erro com a requisição.';
		  				thisEl.snackbarShower = true;
		            }
		        );
		    }
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		v_number_format (number, decimals, dec_point, thousands_sep) {
			return number_format(number, decimals, dec_point, thousands_sep);
		},
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'colaboradores/listagem_colaboradores',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemGeral = r.body.listagemGeral;
	                	thisEl.data.listagemGeralInativos = r.body.listagemGeralInativos;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'colaboradores/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemCargos = r.body.listagemCargos;
	                	thisEl.data.listagemCargosColaboradores = r.body.listagemCargosColaboradores;
	                	thisEl.data.listagemUnidades = r.body.listagemUnidades;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		formatDate (date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		mascara_cep(cep) {
			if (cep != undefined) {
				return cep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
			}
		},
		mascara_rg(rg) {
			if (rg != undefined) {
				return rg.replace(/^(\d{2})(\d{3})(\d{3})([\x\X\d]{1})$/, "$1.$2.$3-$4");
			}
		},
		mascara_cpf(cpf) {
			if (cpf != undefined) {
				return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
			}
		},
		mascara_telefone(telefone) {
			if (telefone != undefined) {
				if (telefone.length == 11) {//celular
					return telefone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, "($1) $2$3-$4");
				} else {//fixo
					return telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
				}
			}
		}
	},
	computed: {
		data_nascimentoToBR(){
			return this.formatDate(this.cadastro.data_nascimento);
		},
		data_nascimentoToBREdit(){
			return this.formatDate(this.dadosEdicao.data_nascimento);
		},
		data_entradaToBR(){
			return this.formatDate(this.cadastro.data_entrada);
		},
		data_entradaToBREdit(){
			return this.formatDate(this.dadosEdicao.data_entrada);
		},
		data_registroToBR(){
			return this.formatDate(this.cadastro.data_registro);
		},
		data_registroToBREdit(){
			return this.formatDate(this.dadosEdicao.data_registro);
		},
		cargosParaCriacao() {
			var ids = [];
			this.data.listagemCargos.forEach(function(item, key){
				if (item.id_cargo != 1) {
					ids.push(item);
				}
			});
			return ids;
		},
		listagemHeaders() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Colaborador',
					value: 'id_colaborador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Status',
					value: 'status',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Login de acesso',
					value: 'id_usuario',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Nome',
					value: 'nome',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'RG',
					value: 'rg',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.rg) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.rg.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'CPF',
					value: 'cpf',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cpf) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cpf.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Telefone',
					value: 'telefone',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Tel. Emergência',
					value: 'telefone_emergencia',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Nascimento',
					value: 'data_nascimento_formated',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Cargo',
					value: 'desc_cargo_colaborador',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_cargo_colaborador) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_cargo_colaborador.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Unidade Operacional',
					value: 'unidade_operacional',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.unidade_operacional) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.unidade_operacional.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Data Entrada',
					value: 'data_entrada_formated',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Registro',
					value: 'data_registro_formated',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Salário',
					value: 'salario',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Periculosidade',
					value: 'periculosidade',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Vale refeição',
					value: 'vale_refeicao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Vale transporte',
					value: 'vale_transporte',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Endereço',
					value: 'endereco',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Bairro',
					value: 'bairro',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CEP',
					value: 'cep',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
