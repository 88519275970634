import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Vuex from 'vuex';
import VueResource from 'vue-resource';

Vue.use(Vuex);
Vue.use(VueResource);

const http = Vue.http;
switch (window.location.hostname) {
	case 'localhost':
		http.options.root = 'http://localhost/precisao/';
		break;
	case 'sisgroup.app.br':
	case 'www.sisgroup.app.br':
		http.options.root = 'https://apis.sisgroup.app.br/';
		break;
	default:
		http.options.root = 'http://localhost/precisao/';
}

//Layouts do sistema----------------------------------------
import defaultLayout from './layouts/Internal.vue';
import loginLayout from './layouts/Login.vue';

Vue.component('default-layout', defaultLayout);
Vue.component('login-layout', loginLayout);
//----------------------------------------------------------

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  http,
  render: function (h) { return h(App) }
}).$mount('#app');