<style>
	a {
		text-decoration: none;
	}
	main {
		background-image: initial;
		background-color: initial;
		position: absolute;
	    left: 0;
	    top: 0;
	    height: 100%;
	    width: 100%;
	    overflow: auto;
	}
	.v-list-group > .v-list-group__header .v-list-group__header__prepend-icon {
		margin-right: 12px !important;
	}
	.v-list-group > .v-list-group__header .v-list-group__header__prepend-icon .v-icon {
	    color: #1a237e !important;
    }
    .v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
		padding-left: 36px !important;
	}
	header.v-app-bar {
		background-image: linear-gradient(45deg, rgb(255, 255, 255), rgb(2, 119, 189) 30%);
		height: 62px !important;
	}

	.v-navigation-drawer__prepend {
		box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
	}
</style>
<template>
	<div class="container">
		<v-app-bar app color="light-blue darken-3">
			<v-app-bar-nav-icon dark @click="showHideNavList = !showHideNavList" style="color:#0277bd;"></v-app-bar-nav-icon>
			<v-avatar rounded class="ml-4" style="width: auto !important;">
				<!-- <img src="../resources/img/logo_final_small.png" alt="BMF"> -->
			</v-avatar>
			<v-spacer></v-spacer>
			<router-link to="/">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn fab dark depressed color="light-blue darken-3" v-bind="attrs" v-on="on">
							<v-icon>home</v-icon>
						</v-btn>
					</template>
					<span>Home</span>
				</v-tooltip>
			</router-link>
			<router-link to="/perfil">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn fab dark depressed color="light-blue darken-3" v-bind="attrs" v-on="on">
							<v-icon>person_outline</v-icon>
						</v-btn>
					</template>
					<span>Perfil do Usuário</span>
				</v-tooltip>
			</router-link>
			<router-link to="/logout">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn fab dark depressed color="light-blue darken-3" v-bind="attrs" v-on="on">
							<v-icon>power_settings_new</v-icon>
						</v-btn>
					</template>
					<span>Sair</span>
				</v-tooltip>
			</router-link>
		</v-app-bar>

		<v-navigation-drawer app v-model="showHideNavList">
			<template v-slot:prepend>
				<v-list-item>
					<v-list-item-avatar>
						<img src="../resources/img/default.png">
					</v-list-item-avatar>

					<v-list-item-content class="font-weight-medium">
						<v-list-item-title>{{data.session.nome_usuario}}</v-list-item-title>
						<v-list-item-subtitle>{{data.session.cargo_usuario}}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</template>

			<v-divider></v-divider>

			<v-list>
				<router-link v-for="link in menuComumStructure" :to='link.url' v-bind:key="link.label">
					<v-list-item link>
						<v-list-item-icon class="mr-3">
							<v-icon class="indigo--text text--darken-4">{{link.icon}}</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="font-weight-medium grey--text text--darken-2">{{link.label}}</v-list-item-title>
					</v-list-item>
				</router-link>

				<v-divider></v-divider>

				<v-list-group :prepend-icon="link.icon" no-action v-for="link in menuInvestimentStructure" v-bind:key="link.label" v-if="link.cargosPermitidos.indexOf(parseInt(data.session.id_cargo_usuario)) >= 0">
			        <template v-slot:activator>
			        	<v-list-item-title class="font-weight-medium grey--text text--darken-2">{{link.label}}</v-list-item-title>
			        </template>
			        <v-divider></v-divider>
			        <v-list-item link v-for="sublink in link.submenuItens" v-if="typeof sublink.cargosPermitidos === 'undefined' || sublink.cargosPermitidos.indexOf(parseInt(data.session.id_cargo_usuario)) >= 0" :to="sublink.url" :key="sublink.url">
						<v-list-item-icon class="mr-3">
							<v-icon class="indigo--text text--darken-4">{{sublink.icon}}</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="font-weight-medium grey--text text--darken-2">{{sublink.label}}</v-list-item-title>
					</v-list-item>
			        <v-divider></v-divider>
			    </v-list-group>

			    <v-divider></v-divider>

			    <router-link to='/logout'>
					<v-list-item link>
						<v-list-item-icon class="mr-3">
							<v-icon class="indigo--text text--darken-4">power_settings_new</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="font-weight-medium grey--text text--darken-2">Sair</v-list-item-title>
					</v-list-item>
				</router-link>
			</v-list>
		</v-navigation-drawer>
	    <v-main>
	        <v-container fluid class="grey--text text--darken-2">
				<slot/>
			</v-container>
	    </v-main>
	    <v-bottom-navigation absolute horizontal height="30" style="line-height: 30px;" class="grey--text">{{ new Date().getFullYear() }} &copy; Todos os direitos reservados</v-bottom-navigation>
	</div>
</template>
<script>
export default {
	data() {
		return {
			data: {
				session: {
					nome_usuario: localStorage.getItem('nome_usuario'),
					id_cargo_usuario: localStorage.getItem('id_cargo_usuario'),
					cargo_usuario: localStorage.getItem('cargo_usuario'),
				}
			},
			showHideNavList: false,
			menuComumStructure: [
				{
					label: 'Home',
					url: '/',
					icon: 'home',
					cargosPermitidos: [1, 2, 3]
				},
				{
					label: 'Perfil do Usuário',
					url: '/perfil',
					icon: 'person',
					cargosPermitidos: [1, 2, 3]
				},
				
				// {
				// 	label: 'Contato a Clientes',
				// 	url: '/contato_clientes',
				// 	icon: 'mdi-phone-check-outline',
				// 	cargosPermitidos: [1, 2, 3]
				// }
			],
			menuInvestimentStructure: [
				{
					label: 'Administrativo',
					icon: 'mdi-speedometer',
					cargosPermitidos: [1, 2],
					submenuItens: [
						{
							label: 'Exportação',
							url: '/administrativo/exportacao_administrativa',
							icon: 'mdi-cloud-download-outline',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Gestão de Celulares',
							url: '/administrativo/celulares',
							icon: 'mdi-cellphone-text',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Gestão de Estoque',
							url: '/administrativo/estoque',
							icon: 'mdi-archive-arrow-down-outline',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Gestão de Veículos',
							url: '/administrativo/veiculos',
							icon: 'mdi-motorbike',
							cargosPermitidos: [1, 2]
						},
						// {
						// 	label: 'Meta de Contato a Clientes',
						// 	url: '/administrativo/meta_contato_clientes',
						// 	icon: 'mdi-phone-check-outline',
						// 	cargosPermitidos: [1, 2]
						// },
						{
							label: 'Produtos',
							url: '/administrativo/produtos',
							icon: 'mdi-package-variant',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Produtos Por Cliente',
							url: '/administrativo/produtos_por_cliente',
							icon: 'mdi-package',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Subprodutos',
							url: '/administrativo/subprodutos',
							icon: 'mdi-trackpad',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Unidades Operacionais',
							url: '/administrativo/unidades_operacionais',
							icon: 'mdi-office-building',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Usuários',
							url: '/administrativo/usuarios',
							icon: 'mdi-account-network',
							cargosPermitidos: [1, 2]
						}
					]
				},
				{
					label: 'Comercial',
					icon: 'mdi-account-tie',
					cargosPermitidos: [1, 2, 3],
					submenuItens: [
						{
							label: 'Cadastro Clientes',
							url: '/administrativo/clientes',
							icon: 'mdi-store-outline',
							cargosPermitidos: [1, 2, 3]
						},
						{
							label: 'Feedback de Clientes',
							url: '/feedback_de_clientes',
							icon: 'mdi-comment-quote-outline',
							cargosPermitidos: [1, 2, 3]
						}
					]
				},
				{
					label: 'Financeiro',
					icon: 'mdi-cash-multiple',
					cargosPermitidos: [1, 2],
					submenuItens: [
						{
							label: 'Contas a Pagar',
							url: '/financeiro/contas_a_pagar',
							icon: 'mdi-cash-marker',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Finanças do Cliente',
							url: '/financeiro/financas_do_cliente',
							icon: 'mdi-cash-refund',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Gerencial Contas a Pagar',
							url: '/financeiro/gerencial_contas_a_pagar',
							icon: 'mdi-chart-bar',
							cargosPermitidos: [1, 2]
						}
					]
				},
				{
					label: 'Operacional',
					icon: 'mdi-chart-arc',
					cargosPermitidos: [1, 2, 3],
					submenuItens: [
						{

							label: 'Gerencial',
							url: '/administrativo/gerencial_vendas',
							icon: 'mdi-finance',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Gerencial Placas',
							url: '/administrativo/gerencial_placas',
							icon: 'mdi-clipboard-check-multiple-outline',
							cargosPermitidos: [1, 2]
						},
						{
							label: 'Histórico de Vendas',
							url: '/administrativo/gerencial_historico_vendas',
							icon: 'mdi-update',
							cargosPermitidos: [1, 2, 3]
						},
						{
							label: 'Registro de Vendas',
							url: '/registro_de_vendas',
							icon: 'mdi-credit-card-marker-outline',
							cargosPermitidos: [1, 2, 3]
						}
					]
				},
				{
					label: 'RH',
					icon: 'mdi-account-group',
					cargosPermitidos: [1, 2],
					submenuItens: [
						{
							label: 'Colaboradores',
							url: '/administrativo/colaboradores',
							icon: 'mdi-briefcase-account-outline',
							cargosPermitidos: [1, 2]
						},
					]
				}
			]
		}
	},
	methods: {
	}
};
</script>