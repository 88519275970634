<template>
	<div class="administrativo_clientes">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Clientes</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
				<v-dialog scrollable v-model="showDialogCadastro" max-width="700">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
							<v-icon left>add</v-icon>
							<span>Novo Cliente</span>
						</v-btn>
					</template>
					<v-card height="fit-content">
						<v-system-bar color="blue darken-4"></v-system-bar>

						<v-card-title class="headline grey--text text--darken-2">Cadastrar novo cliente</v-card-title>

						<v-card-text>
							<v-form class="my-3" ref="formCadastramento">
								<v-row>
									<v-col cols="12">
										<v-autocomplete prepend-icon="mdi-account-box-multiple" label="Tipo do Cliente" v-model="cadastro.id_tipo_cliente" :items="data.listagemTiposClientes" item-text="desc_tipo_cliente" item-value="id_tipo_cliente" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
								</v-row>
								<v-row v-if="cadastro.id_tipo_cliente == 1">
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-clipboard-text" label="CNPJ" v-model="cadastro.cnpj" :rules="rules.inputCnpj" counter maxlength="14"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-card-bulleted-outline" label="Inscrição Estadual" v-model="cadastro.inscricao_estadual" :rules="rules.inputInscricaoEstadual" counter maxlength="14"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field prepend-icon="mdi-office-building" label="Nome Fantasia" v-model="cadastro.nome_fantasia" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field prepend-icon="mdi-card-text-outline" label="Razão Social" v-model="cadastro.razao_social" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="cadastro.endereco" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-map-check" label="Bairro" v-model="cadastro.bairro" counter maxlength="50"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-map-marker-circle" label="CEP" v-model="cadastro.cep" :rules="rules.inputCEP" counter maxlength="8"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-phone" label="Telefone (ex.: 11953534747)" v-model="cadastro.telefone" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-message-processing-outline" label="Observações" v-model="cadastro.observacao" counter maxlength="50"></v-text-field>
									</v-col>
								</v-row>
								<v-row v-if="cadastro.id_tipo_cliente == 2">
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-clipboard-text" label="CPF" v-model="cadastro.cpf" :rules="rules.inputCpf" counter maxlength="11"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-card-text-outline" label="Nome" v-model="cadastro.nome" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="cadastro.endereco" counter maxlength="100"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-map-check" label="Bairro" v-model="cadastro.bairro" counter maxlength="50"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-map-marker-circle" label="CEP" v-model="cadastro.cep" :rules="rules.inputCEP" counter maxlength="8"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-phone" label="Telefone (ex.: 11953534747)" v-model="cadastro.telefone" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-message-processing-outline" label="Observações" v-model="cadastro.observacao" counter maxlength="50"></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions class="my-5">
							<v-btn depressed dark color="blue darken-4" @click="showDialogCadastro = !showDialogCadastro">
								<v-icon left>close</v-icon>
								<span>Cancelar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="submitCadastro" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar</span>
							</v-btn>
						</v-card-actions>

						<v-system-bar color="blue-grey darken-1"></v-system-bar>
					</v-card>
				</v-dialog>
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Pessoa Física</h5>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="CPF" v-model="filters.cpf" outlined dense counter clearable maxlength="11"></v-text-field>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="Nome" v-model="filters.nome" outlined dense clearable></v-text-field>
							</v-flex>
						</v-row>
					</v-flex>
					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Pessoa Jurídica</h5>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="CNPJ" v-model="filters.cnpj" outlined dense counter clearable maxlength="14"></v-text-field>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="Razão Social" v-model="filters.razao_social" outlined dense clearable></v-text-field>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="Nome Fantasia" v-model="filters.nome_fantasia" outlined dense clearable></v-text-field>
							</v-flex>
						</v-row>
					</v-flex>
					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Pessoa Física e Jurídica</h5>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="Telefone" v-model="filters.telefone" outlined dense clearable></v-text-field>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="Endereço" v-model="filters.endereco" outlined dense clearable></v-text-field>
							</v-flex>
						</v-row>
					</v-flex>

					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-card>
					<v-tabs v-model="activeTab" background-color="grey lighten-4">
						<v-tabs-slider color="indigo darken-4"></v-tabs-slider>
						<v-tab v-for="item in tablist" :key="item" class="indigo--text text--darken-4">{{ item }}</v-tab>
					</v-tabs>

					<v-tabs-items v-model="activeTab">
						<v-tab-item key="Pessoa Jurídica">
							<v-card flat>
								<v-card-text>
									<v-data-table
										:headers="listagemHeadersPessoaJuridica"
										:items="data.listagemPessoaJuridica"
										:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
										class="grey lighten-4 grey--text text--darken-2"
										style="white-space:nowrap;"
									>
										<template v-slot:item.actions="{ item }">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
														<v-icon>menu</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item>
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="editarCliente(item, 'PJ')">
																<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar Cliente
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item>
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="excluirCliente(item, 'PJ')">
																<v-icon color="indigo darken-4" class="mr-2">mdi-delete-outline</v-icon> Excluir Cliente
															</v-list-item-title>
														</router-link>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
										<template v-slot:item.cnpj="{ item }">
											{{ mascara_cnpj(item.cnpj) }}
										</template>
										<template v-slot:item.cep="{ item }">
											{{ mascara_cep(item.cep) }}
										</template>
										<template v-slot:item.telefone="{ item }">
											{{ mascara_telefone(item.telefone) }}
										</template>
										<template v-slot:item.dt_criacao="{ item }">
											{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
										</template>
										<template v-slot:item.dt_alteracao="{ item }">
											{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
										</template>
									</v-data-table>
									<v-flex xs12 class="text-end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
													<v-icon>file_download</v-icon>
													<span>Exportar planilha</span>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemPessoaJuridica, 'pessoa_juridica')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
														</v-list-item-title>
													</router-link>
												</v-list-item>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros('PJ')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
														</v-list-item-title>
													</router-link>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-flex>
								</v-card-text>
							</v-card>
						</v-tab-item>
						<v-tab-item key="Pessoa Física">
							<v-card flat>
								<v-card-text>
									<v-data-table
										:headers="listagemHeadersPessoaFisica"
										:items="data.listagemPessoaFisica"
										:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
										class="grey lighten-4 grey--text text--darken-2"
										style="white-space:nowrap;"
									>
										<template v-slot:item.actions="{ item }">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
														<v-icon>menu</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item>
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="editarCliente(item, 'PF')">
																<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar Cliente
															</v-list-item-title>
														</router-link>
													</v-list-item>
													<v-list-item>
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="excluirCliente(item, 'PF')">
																<v-icon color="indigo darken-4" class="mr-2">mdi-delete-outline</v-icon> Excluir Cliente
															</v-list-item-title>
														</router-link>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
										<template v-slot:item.cpf="{ item }">
											{{ mascara_cpf(item.cpf) }}
										</template>
										<template v-slot:item.cep="{ item }">
											{{ mascara_cep(item.cep) }}
										</template>
										<template v-slot:item.telefone="{ item }">
											{{ mascara_telefone(item.telefone) }}
										</template>
										<template v-slot:item.dt_criacao="{ item }">
											{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
										</template>
										<template v-slot:item.dt_alteracao="{ item }">
											{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
										</template>
									</v-data-table>
									<v-flex xs12 class="text-end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
													<v-icon>file_download</v-icon>
													<span>Exportar planilha</span>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemPessoaFisica, 'pessoa_fisica')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
														</v-list-item-title>
													</router-link>
												</v-list-item>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros('PF')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
														</v-list-item-title>
													</router-link>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-flex>
								</v-card-text>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogEditPF" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar Cliente</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formEdicao">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-clipboard-text" label="CPF" v-model="dadosEdicao.cpf" :rules="rules.inputCpf" counter maxlength="11"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Nome" v-model="dadosEdicao.nome" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="dadosEdicao.endereco" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-map-check" label="Bairro" v-model="dadosEdicao.bairro" counter maxlength="50"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-map-marker-circle" label="CEP" v-model="dadosEdicao.cep" :rules="rules.inputCEP" counter maxlength="8"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-phone" label="Telefone (ex.: 11953534747)" v-model="dadosEdicao.telefone" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-message-processing-outline" label="Observações" v-model="dadosEdicao.observacao" counter maxlength="50"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEditPF = !showDialogEditPF">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao('PF')" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogEditPJ" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar Cliente</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formEdicao">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-clipboard-text" label="CNPJ" v-model="dadosEdicao.cnpj" :rules="rules.inputCnpj" counter maxlength="14"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-bulleted-outline" label="Inscrição Estadual" v-model="dadosEdicao.inscricao_estadual" :rules="rules.inputInscricaoEstadual" counter maxlength="14"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-office-building" label="Nome Fantasia" v-model="dadosEdicao.nome_fantasia" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Razão Social" v-model="dadosEdicao.razao_social" :rules="rules.inputTextValidate" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="dadosEdicao.endereco" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-map-check" label="Bairro" v-model="dadosEdicao.bairro" counter maxlength="50"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-map-marker-circle" label="CEP" v-model="dadosEdicao.cep" :rules="rules.inputCEP" counter maxlength="8"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-phone" label="Telefone (ex.: 11953534747)" v-model="dadosEdicao.telefone" :rules="rules.inputTelefone" counter maxlength="11"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-message-processing-outline" label="Observações" v-model="dadosEdicao.observacao" counter maxlength="50"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEditPJ = !showDialogEditPJ">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao('PJ')" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogExcluir" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Excluir Cliente</v-card-title>

				<v-card-text>
					<v-alert prominent text type="warning" border="left" icon="mdi-alert-outline">
						O cliente será excluído. Esta ação não poderá ser desfeita.
					</v-alert>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogExcluir = !showDialogExcluir">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitExclusao()" :loading="loadingStatus">
						<v-icon left>mdi-delete-outline</v-icon>
						<span>Excluir</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo: Number(localStorage.getItem('id_cargo'))
			},
			tablist: ['Pessoa Jurídica', 'Pessoa Física'],
			activeTab: null,
			cadastro: {
				id_tipo_cliente: 0,
				cpf: '',
				cnpj: '',
				inscricao_estadual: '',
				nome: '',
				razao_social: '',
				nome_fantasia: '',
				endereco: '',
				bairro: '',
				cep: '',
				telefone: '',
				observacao: ''
			},
			rules: {
				inputCpf: [
					v => v != '' || 'Campo obrigatório',
					v => v.length == 11 || 'Exatamente 11 dígitos, preenchidos com zeros a esquerda',
					v => /^\d+$/.test(v) || 'Somente números'
				],
				inputCnpj: [
					v => v != '' || 'Campo obrigatório',
					v => v.length == 14 || 'Exatamente 14 dígitos, preenchidos com zeros a esquerda',
					v => /^\d+$/.test(v) || 'Somente números'
				],
				inputInscricaoEstadual: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 && v.length <= 14 || 'Até 14 caracteres'
				],
				inputTelefone: [
					v => !v || v.length >= 10 || 'Telefones com DDD, entre 10 (fixo) e 11 (celular) dígitos',
					v => !v || v.length <= 11 || 'Telefones com DDD, entre 10 (fixo) e 11 (celular) dígitos',
					v => !v || /^\d+$/.test(v) || 'Somente números'
				],
				inputCEP: [
					v => !v || v.length == 8 || 'CEP tem 8 dígitos',
					v => !v || /^\d+$/.test(v) || 'Somente números'
				],
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				],
				selectValidate: [
					v => v != null || 'Campo obrigatório',
					v => v != '' || 'Campo obrigatório'
				]
			},
			data: {
				listagemPessoaFisica: [{cpf: 'Buscando...'}],
				listagemPessoaJuridica: [{cnpj: 'Buscando...'}],
				listagemTiposClientes: [{desc_tipo_cliente: 'Buscando...'}]
			},
			filters: {
				cpf: '',
				nome: '',
				cnpj: '',
				razao_social: '',
				nome_fantasia: '',
				telefone: '',
				endereco: ''
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,
			
			dadosEdicao: {},
			showDialogEditPF: false,
			showDialogEditPJ: false,

			dadosExclusao: {},
			showDialogExcluir: false
		}
	},
	methods: {
		exportarListagemFiltros(natureza) {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			dataToSend.natureza = natureza;
			
			thisEl.$http.post(//requisição ajax
                'cliente/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'clientes', []);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral(dadosListagem, tipo) {
			let dados = {};

			dados.headers = Object.keys(dadosListagem[0]).map((item) => {
				return {name: item};
			});

			dados.values = dadosListagem;
			
			this.exportXlsAjax(dados, 'clientes_' + tipo, []);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		submitCadastro () {
			if (this.$refs.formCadastramento.validate()) {

				if (this.cadastro.id_tipo_cliente == 1 && this.cadastro.razao_social == '' && this.cadastro.nome_fantasia == '') {
					this.snackbarColor = 'red';
      				this.snackbarText = 'Informe ao menos um Nome Fantasia ou Razão Social';
      				this.snackbarShower = true;

      				return false;
				}
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.cadastro;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'cliente/criar_cliente',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogCadastro = false;

	                        		thisEl.cadastro = {
										id_tipo_cliente: 0,
										cpf: '',
										cnpj: '',
										inscricao_estadual: '',
										nome: '',
										razao_social: '',
										nome_fantasia: '',
										endereco: '',
										bairro: '',
										cep: '',
										telefone: '',
										observacao: ''
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		excluirCliente(dados) {
			this.dadosExclusao = {...dados};
			this.showDialogExcluir = true;
		},
		submitExclusao() {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosExclusao;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
                'cliente/excluir_cliente',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogExcluir = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		editarCliente (dados, natureza) {
			this.dadosEdicao = {...dados};
			eval(`this.showDialogEdit${natureza} = true;`);
		},
		submitEdicao (natureza) {
			if (this.$refs.formEdicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.dadosEdicao;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'cliente/atualizar_cliente',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		eval(`thisEl.showDialogEdit${natureza} = false;`);
	                        	}, 1000);

                            	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'cliente/listagem_clientes',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemPessoaFisica = r.body.listagemPessoaFisica;
	                	thisEl.data.listagemPessoaJuridica = r.body.listagemPessoaJuridica;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'cliente/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemTiposClientes = r.body.listagemTiposClientes;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		mascara_cep(cep) {
			if (cep != undefined) {
				return cep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
			}
		},
		mascara_cnpj(cnpj) {
			if (cnpj != undefined) {
				return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
			}
		},
		mascara_cpf(cpf) {
			if (cpf != undefined) {
				return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
			}
		},
		mascara_telefone(telefone) {
			if (telefone != undefined) {
				if (telefone.length == 11) {//celular
					return telefone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, "($1) $2$3-$4");
				} else {//fixo
					return telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
				}
			}
		}
	},
	computed: {
		listagemHeadersPessoaFisica() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Cliente',
					value: 'id_cliente',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CPF',
					value: 'cpf',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cnpj) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cpf.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Nome',
					value: 'nome',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Telefone',
					value: 'telefone',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.telefone) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.telefone.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Endereço',
					value: 'endereco',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.endereco) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.endereco.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Bairro',
					value: 'bairro',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CEP',
					value: 'cep',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Observações',
					value: 'observacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				},
			];
		},
		listagemHeadersPessoaJuridica() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Cliente',
					value: 'id_cliente',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CNPJ',
					value: 'cnpj',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cnpj) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cnpj.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Inscrição Estadual',
					value: 'inscricao_estadual',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Razão Social',
					value: 'razao_social',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.razao_social) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.razao_social.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Nome Fantasia',
					value: 'nome_fantasia',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome_fantasia) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome_fantasia.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Telefone',
					value: 'telefone',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.telefone) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.telefone.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Endereço',
					value: 'endereco',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.endereco) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.endereco.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Bairro',
					value: 'bairro',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CEP',
					value: 'cep',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Observações',
					value: 'observacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
